import { post, get } from "../request";
import { API_URLS } from "src/constants/apiUrls";


export const fetchUserProfile = async (param: any) => {
  try {
    const data = await post(API_URLS.fetchUserProfile, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchPopularRewards = async () => {
  try {
    const data = await get(API_URLS.fetchPopularRewards);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchUser = async (param: any) => {
  try {
    const data = await post(API_URLS.fetchUser, param);
    return data;
  } catch (error) {
    throw error;
  }
};