import React, { useState } from 'react';
import images from 'src/assets/images';

interface InviteFriendsCardProps {
  inviteLink: string;
}

const InviteFriendsCard: React.FC<InviteFriendsCardProps> = ({ inviteLink }) => {
  const [selectedIcon, setSelectedIcon] = useState<string>('message'); // Default selected icon

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    alert('Link copied to clipboard!');
  };

  const iconStyle = (iconName: string) =>
    `rounded-full p-6 ${selectedIcon === iconName ? 'bg-[#00CC52]' : 'bg-[#172E46]'}`;

  return (
    <div className="w-full rounded-2xl bg-[#0A1E32] p-4">
      <div className="text-white text-2xl font-semibold mb-2">
        <span style={{ background: 'linear-gradient(to right, #EC4899, #FB923C)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
          Get your friends to join you!
        </span>
      </div>
      <div className="text-lg text-white mb-4">
        You’ll Receive 50 fundcoin for each successful invite.
      </div>

      <div className="flex justify-around items-center mb-4">
        <div className="flex flex-col items-center">
          <div
            className={iconStyle('message')}
            onClick={() => setSelectedIcon('message')}
          >
            <img src={images.message_icon} alt="Message" className="w-8 h-8" />
          </div>
          <span className="text-xs text-white mt-1">Message</span>
        </div>
        <div className="flex flex-col items-center">
          <div
            className={iconStyle('messenger')}
            onClick={() => setSelectedIcon('messenger')}
          >
            <img src={images.fb_messenger_icon} alt="Messenger" className="w-8 h-8" />
          </div>
          <span className="text-xs text-white mt-1">Messenger</span>
        </div>
        <div className="flex flex-col items-center">
          <div
            className={iconStyle('instagram')}
            onClick={() => setSelectedIcon('instagram')}
          >
            <img src={images.instagram_icon} alt="Instagram" className="w-8 h-8" />
          </div>
          <span className="text-xs text-white mt-1">Instagram</span>
        </div>
        <div className="flex flex-col items-center">
          <div
            className={iconStyle('more')}
            onClick={() => setSelectedIcon('more')}
          >
            <img src={images.more_icon} alt="More" className="w-8 h-8" />
          </div>
          <span className="text-xs text-white mt-1">More...</span>
        </div>
      </div>
      <div className="flex items-center justify-between bg-[#102a43] rounded-md px-4 py-2">
        <span className="text-white text-sm">{inviteLink}</span>
        <button
          className="bg-gradient-to-r from-pink-500 to-orange-400 text-white px-3 py-1 rounded-md font-semibold"
          onClick={handleCopy}
        >
          Copy
        </button>
      </div>
    </div>
  );
};

export default InviteFriendsCard;
