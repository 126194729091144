import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/contexts/authContext';

interface PublicRouteProps {
  element: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { isAuthenticated, isNewUser } = useAuth();
  if (isAuthenticated) {
    if (isNewUser) {
      return <Navigate to="/congratulations" />;
    } else {
      return <Navigate to="/dashboard" />;
    }
  }

  return element;
};

export default PublicRoute;
