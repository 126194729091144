import React from 'react';
import images from 'src/assets/images';

interface DonationOptionCardProps {
  amount: number;
  label?: string;
  coins: number;
  checked?: boolean;
  backgroundColor?: string;
  onCheckChange?: () => void; // Notice the change here, no more `checked` parameter.
}

const DonationOptionCard: React.FC<DonationOptionCardProps> = ({
  amount,
  label,
  coins,
  checked = false,
  backgroundColor = 'bg-white',
  onCheckChange,
}) => {
  // Determine if the text, border, and checkbox background should be adjusted based on background color and label
  const textColor = backgroundColor === 'bg-transparent' || backgroundColor !== 'bg-white' ? 'text-white' : 'text-black';
  const borderColor = backgroundColor === 'bg-transparent' || backgroundColor !== 'bg-white' ? 'rgba(255, 255, 255, 0.1)' : '#F0F2F5';
  const checkboxBackground = backgroundColor === 'bg-transparent' || backgroundColor !== 'bg-white' ? 'rgba(255, 255, 255, 0.1)' : '#F0F2F5';
  const sizeClasses = !label ? 'text-3xl' : 'text-2xl';
  const coinContainerSize = !label ? 'text-2xl' : 'text-xl';
  const coinImageSize = !label ? 'w-6 h-6' : 'w-5 h-5';

  return (
    <div
      className={`p-4 shadow-sm rounded-2xl cursor-pointer ${backgroundColor}`}
      style={{ borderColor, borderWidth: '1px' }}
      onClick={onCheckChange} // Trigger parent function on click
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-4">
            <div className="relative">
              <div
                className={`w-6 h-6 rounded-full flex items-center justify-center cursor-pointer`}
                style={{ backgroundColor: checked ? '#3D86FF' : checkboxBackground }} // Apply checked prop directly
              >
                {checked && <div className="w-3 h-3 bg-white rounded-full"></div>}
              </div>
            </div>
          </div>
          <div>
            <p className={`font-bold ${textColor} ${sizeClasses}`}>
              ${amount}
              <span className={`font-normal ${textColor} ${!label ? 'text-xl' : 'text-lg'}`}>/month</span>
            </p>
          </div>
        </div>
        <div className={`flex items-center bg-[#0A1E32] ${coinContainerSize} text-white px-3 rounded-full`}>
          <img src={images.fund_coin_icon} alt="Coin Icon" className={`${coinImageSize} mr-2`} />
          {coins.toLocaleString()}
        </div>
      </div>
      {label && <p className={`text-sm ${textColor === 'text-white' ? 'text-[#F0F2F5]' : 'text-[#8197AD]'} mt-2`}>{label}</p>}
    </div>
  );
};

export default DonationOptionCard;
