import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import images from 'src/assets/images';
import Button from 'src/components/common/button';
import DonationRecieptCard from 'src/components/chooseDonation/donationReceiptCard';
import { createCheckoutSession, checkStripePaymentMethod, createCauseSubscription } from 'src/services/payment/api';
import { fetchCause } from 'src/services/cause/api';
import { useAuth } from 'src/contexts/authContext';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || "0");
const causeproportionalAmount: number = parseFloat(process.env.REACT_APP_CAUSE_COIN_PROPORTIONAL_AMOUNT || "0");
const appproportionalAmount: number = parseFloat(process.env.REACT_APP_FUNDR_COIN_PROPORTIONAL_AMOUNT || "0");

const PaymentReceipt: React.FC = () => {
  const { user, selectedCauseId, donationAmount, appDonationAmount, setCoinsEarned }: any = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cause, setCause] = useState<any>(null);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [coinsEarned, setCoinsEarnedState] = useState(0); // State to hold calculated coins
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  useEffect(() => {
    const fetchCauseDetails = async () => {
      if (selectedCauseId) {
        try {
          const causeData = await fetchCause({ id: selectedCauseId });
          setCause(causeData);
        } catch (error) {
          console.error("Failed to fetch cause details:", error);
        }
      }
    };

    fetchCauseDetails();
  }, [selectedCauseId]);

  useEffect(() => {
    const checkPaymentMethod = async () => {
      try {
        const result: any = await checkStripePaymentMethod({ userId: user.id });
        if (result && result.hasPaymentMethods) {
          console.log("result", result);
          setHasPaymentMethod(true);
          const paymentMethods = result.paymentMethods;
          setPaymentMethodId(paymentMethods[0].id);
        }
      } catch (error) {
        console.error("Failed to check payment methods:", error);
      }
    };

    checkPaymentMethod();
  }, [user.id]);

  useEffect(() => {
    const calculateCoins = () => {
      const causeCoins = donationAmount * causeproportionalAmount;
      let appCoins = appDonationAmount * appproportionalAmount;
      if (appDonationAmount === 0.50) {
        appCoins = appDonationAmount * 100;
      }
      const totalCoins = causeCoins + appCoins;
      setCoinsEarned(totalCoins);
      setCoinsEarnedState(totalCoins); // Store in local state for use in handleCheckout
    };

    calculateCoins();
  }, [donationAmount, appDonationAmount, setCoinsEarned]);

  const handleBack = () => {
    navigate('/choose-donation-amount');
  };

  const handleCheckout = async () => {
  setLoading(true);

  try {
    const stripe = await stripePromise;
    const totalAmount = donationAmount + appDonationAmount;

    if (hasPaymentMethod) {
      const subscriptionData: any = await createCauseSubscription({
        userId: user.id,
        amount: totalAmount,
        causeId: selectedCauseId,
        coinsEarned: coinsEarned,
        paymentMethodId: paymentMethodId,
      });

      if (subscriptionData) {
        if (subscriptionData.requiresAction || subscriptionData.status === 'incomplete') {
          const { error } = await stripe!.confirmCardPayment(subscriptionData.paymentIntentClientSecret);

          if (error) {
            console.error('3D Secure authentication failed:', error.message);
            setLoading(false);
            return;
          }
          navigate(`/cause-payment-success/${subscriptionData.id}`);
        } else if (subscriptionData.status === 'active') {
          navigate(`/cause-payment-success/${subscriptionData.id}`);
        } else {
          console.error('Failed to create subscription:', subscriptionData);
          setLoading(false);
        }
      }
    } else {
      const data: any = await createCheckoutSession({
        userId: user.id,
        amount: totalAmount,
        causeId: selectedCauseId,
        coinsEarned: coinsEarned, 
      });

      const { error } = await stripe!.redirectToCheckout({ sessionId: data.id });

      if (error) {
        console.error('Stripe Checkout error:', error);
        setLoading(false);
      }
    }
  } catch (error) {
    console.error('Error processing checkout or subscription:', error);
    setLoading(false);
  }
};


  const transactionFee = parseFloat((donationAmount * 0.03).toFixed(2));

  return (
    <>
      <div className="p-4 relative">
        <div className="absolute top-6 flex justify-between items-center w-full px-4">
          <img
            src={images.leftarrow}
            alt="Back"
            className="w-4 h-4 cursor-pointer mt-3"
            onClick={handleBack}
          />
        </div>

        <div className="mt-16 w-full max-w-md text-left">
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Way to go, {user.firstName || 'there'}
          </h1>
          <p className="text-md mb-4 text-[#1E1E1E]">
            Next let’s complete your donation by sending it to your cause: <strong>{cause?.name || "Loading..."}</strong>.
          </p>
        </div>

        <div className="mt-8">
          <DonationRecieptCard
            causeWallpaper={cause?.imageUrl || images.cause_logo}
            hubDonation={donationAmount}
            fundrDonation={appDonationAmount}
            transactionFee={transactionFee}
            userId={user.id}
          />
        </div>

        <div className="mt-4">
      <Button onClick={handleCheckout} disabled={loading}>
        {loading ? 'Processing...' : hasPaymentMethod ? 'Submit my donation' : 'Add my Payment Method'}
      </Button>
    </div>
      </div>
    </>
  );
};

export default PaymentReceipt;
