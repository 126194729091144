import React from 'react';
import images from 'src/assets/images';

interface RewardCardProps {
  imageUrl: string;
  category: string;
  title: string;
  coinAmount: number;
  buyersCount: number;
  buyersImages: string[];
  backgroundColor?: string; 
  onClick: () => void;
}

const RewardCard: React.FC<RewardCardProps> = ({
  imageUrl,
  category,
  title,
  coinAmount,
  buyersCount,
  buyersImages,
  backgroundColor = '#00BCD5',
  onClick,
}) => {
  const textColor = backgroundColor === '#00BCD5' ? 'text-white' : 'text-black';

  return (
    <div
      onClick={onClick}
      className="rounded-xl shadow-lg overflow-hidden h-44 flex bg-white w-full max-w-[107%] md:max-w-full">
      <div className={`flex flex-col justify-between p-4 w-2/3 ${textColor}`} style={{ backgroundColor }}>
        <div>
          <h3 className="text-xs uppercase">{category}</h3>
          <h2
            className="text-xl font-bold mt-1"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </h2>
        </div>
        <div className="flex items-center">
          {buyersImages.slice(0, 3).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Buyer ${index + 1}`}
              className="w-7 h-7 rounded-full border-2 border-white -ml-3 first:ml-0"
            />
          ))}
          <div
            className="w-7 h-7 bg-white text-black text-xs rounded-full flex items-center justify-center border-2 border-white -ml-3"
            style={{ zIndex: buyersImages.length + 1 }}
          >
            {buyersCount}
          </div>
          <span className="ml-2 text-xs">
            Others bought this
          </span>
        </div>
      </div>

      <div className="relative w-1/2">
        <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
        <div className="absolute top-2 right-2 p-2 h-7 w-20 rounded-full bg-[#0A1E32] flex items-center bg-opacity-80">
          <img src={images.fund_coin_icon} alt="Coin Icon" className="w-4 h-4" />
          <span className="text-white ml-2">{coinAmount}</span>
        </div>
      </div>
    </div>
  );
};

export default RewardCard;
