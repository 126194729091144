import React, { useEffect } from 'react';
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import ReferralStatsCard from 'src/components/refer/referralStatsCard';
import InviteFriendsCard from 'src/components/refer/inviteFriendsCard';
import ReferredUserCard from 'src/components/refer/referredUserCard';
import images from 'src/assets/images';

const Refer: React.FC = () => {
  
  useEffect(() => {
    const fetchData = async () => {
      try {
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  });

  return (
    <>
      <div className="p-4 mt-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Referrals</h1>
        </div>
      </div>

      <div className="flex-grow px-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
        <div className="mt-4 space-y-4">
          <ReferralStatsCard totalReferrals={22} totalDonations={1950} />
        </div>

        <div className="mt-8 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">Share Link</h2>
          <div className="mt-2">
            <InviteFriendsCard inviteLink="Fundcoin/david123456" />
          </div>
        </div>

        <div className="mt-8 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">My Referrals</h2>
          <div className="mt-2 space-y-2">
            <ReferredUserCard name="John Doerson" donation="$10" avatarUrl={images.cause_logo} />
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
        <BottomTabsBar />
      </div>
    </>
  );
};

export default Refer;
