import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string; // New prop for additional class names
}

const Button: React.FC<ButtonProps> = ({ children, onClick, disabled = false, className = '' }) => {
  return (
    <button
      className={`w-full py-3 px-4 rounded-custom text-lg ${!disabled ? 'bg-[#3D86FF] text-white' : 'bg-[#B8D4FF] text-white cursor-not-allowed'} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
