import React, { useState, useEffect } from 'react';
import images from 'src/assets/images';
import Button from 'src/components/common/button';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import RecommendedCauseCard from 'src/components/selectcause/recommendedCauseCard';
import CauseDetail from './causeDetail';
import CauseSelectSuccess from './causeSelectSuccess';
import { fetchCauses } from 'src/services/cause/api';
import { useAuth } from 'src/contexts/authContext';

type Cause = {
  id: string;
  name: string;
  mission: string;
  goalAmount: number;
  currentAmount: number;
  causeProfileUrl: string;
  causeLogo: string;
  imageUrl: string;
  buyersCount: number;
  profilePictures: string[];
  CategoryName: string;
  isFeatured: boolean;
};

const SelectCause: React.FC = () => {
  const navigate = useNavigate();
  const { setSelectedCauseId } = useAuth();
  const [recommendedCause, setRecommendedCause] = useState<Cause | null>(null);
  const [selectedCause, setSelectedCause] = useState<Cause | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const handleBack = () => {
    navigate('/dashboard');
  };

  const handleLearnMore = (cause: Cause) => {
    setSelectedCause(cause);
  };

  const handleSupportCause = () => {

    const causeToSupport = selectedCause || recommendedCause;
    if (causeToSupport) {
      setSelectedCauseId(causeToSupport.id); // Save the cause ID in the context
      setShowSuccess(true); // Show the success screen
    }
  };

  const handleSelectDifferentCause = () => {
    navigate('/select-cause-two');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const causesData: any = await fetchCauses();
        const featuredCause = causesData.find((cause: Cause) => cause.isFeatured);
        setRecommendedCause(featuredCause || null);
      } catch (error) {
        console.error('Failed to fetch causes:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="p-4">
        <div className="absolute top-6 flex justify-between items-center w-full px-4">
          <img
            src={images.leftarrow}
            alt="Back"
            className="w-4 h-4 cursor-pointer mt-3"
            onClick={handleBack}
          />
          <div className="flex-grow flex justify-center">
            <ProgressBar step={0} color="#0A1E32" />
          </div>
        </div>

        <div className="mt-16 w-full max-w-md text-left">
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Choose Your Cause
          </h1>
          <p className="text-xl mb-4 text-[#1E1E1E]">
            Select the cause you would like to support.
            <span className="text-[#1E1E1E] opacity-60">
              (Don't worry, you can change this later)
            </span>
          </p>
        </div>
      </div>

      <div className="flex-grow px-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
        <div className="mt-4 space-y-4">
          {recommendedCause ? (
            <RecommendedCauseCard
              causeWallPaper={recommendedCause.imageUrl}
              causeLogo={recommendedCause.causeProfileUrl}
              causeName={recommendedCause.name}
              causeMission={recommendedCause.mission}
              buyersCount={recommendedCause.buyersCount || 0}
              onClick={() => handleLearnMore(recommendedCause)}
            />
          ) : (
            <p className="text-center text-[#787B7E] mt-10">No recommended cause available</p>
          )}

          <div className="text-center mt-6">
            <p className="text-sm text-[#8197AD]">OR</p>
            <button className="text-[#3D86FF] mt-2" onClick={handleSelectDifferentCause}>
              Select a different Cause
            </button>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto px-4 py-4 bg-white">
        <Button onClick={handleSupportCause}>Support this Cause</Button>
      </div>

      {selectedCause && (
        <CauseDetail cause={selectedCause} onClose={() => setSelectedCause(null)} />
      )}

      {showSuccess && <CauseSelectSuccess onClose={() => setShowSuccess(false)} />} 
    </>
  );
};

export default SelectCause;
