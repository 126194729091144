import React from 'react';
import images from 'src/assets/images';

interface TravelInfoCardProps {
  items: string[];
}

const TravelInfoCard: React.FC<TravelInfoCardProps> = ({ items }) => {
  return (
    <div className="bg-[#EEF1F5] rounded-2xl shadow-md p-6 w-full">
      <ul className="space-y-3">
        {items.map((item, index) => (
          <li key={index} className="flex items-start">
            <img
              src={images.success_icon}
              alt="Check Icon"
              className="w-6 h-6 mr-3"
            />
            <span className="text-md text-gray-700">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TravelInfoCard;
