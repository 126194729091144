import { CSSProperties } from 'react';

export const sliderStyles = {
  container: {
    position: 'relative' as CSSProperties['position'],
    borderRadius: '12px',
    width: '100%',
    marginLeft: '4px',
  },
  dotsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute' as CSSProperties['position'],
    bottom: '-40px',
    
  },
  dotsList: {
    margin: '0',
    display: 'flex',
    gap: '5px',
    listStyle: 'none',
    padding: '0',
  },
  dot: (isActive: boolean) => ({
    width: isActive ? '24px' : '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: isActive ? '#000000' : '#9DA8B8',
    cursor: 'pointer',
  }),
};
