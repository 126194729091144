import React, { useState } from 'react';
import images from 'src/assets/images';
import { useAuth } from 'src/contexts/authContext';

interface TopBarProps {
  profileImage: string;
}

const TopBar: React.FC<TopBarProps> = ({ profileImage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { logout } = useAuth();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="relative w-full h-16 sm:h-20 bg-white flex items-center justify-between px-4 sm:px-6">
      <img src={images.topbar_logo} alt="Logo" className="h-6 sm:h-8" />
      <div className="relative">
        <img
          src={profileImage}
          alt="Profile"
          className="w-7 h-7 sm:w-8 sm:h-8 rounded-full object-cover cursor-pointer"
          onClick={toggleDropdown}
        />
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-28 bg-white border rounded-lg shadow-lg z-50">
            <button
              onClick={handleLogout}
              className="block w-full px-4 py-2 text-left text-gray-700 rounded-lg hover:bg-[#E5EAEF] focus:outline-none"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
