import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex items-center justify-center min-h-screen h-[100vh] bg-gray-200 overflow-x-hidden">
      <div className="relative w-full max-w-[548px] h-full h-[100vh] md:h-[812px] md:w-full md:max-w-[548px] rounded-none md:rounded-3xl shadow-md overflow-hidden flex flex-col bg-white">
        {children}
      </div>
    </div>
  );
};

export default Layout;
