import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'src/components/common/button';
import { InputField } from 'src/components/common/inputField';
import images from 'src/assets/images';
import ProgressBar from 'src/components/common/progressBar';
import { LoginUser } from 'src/services/auth/api';
import { useAuth } from 'src/contexts/authContext';
import useRedirectIfNoState from 'src/hooks/redirectHook';
import { toast } from 'react-toastify';

const PasswordScreen: React.FC = () => {
  const { login } = useAuth();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state as { email: string} || {};

  useRedirectIfNoState('email');

  const handleBack = () => {
    navigate('/email'); 
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === 'string') {
      setPassword(e); // Handle string input
    } else {
      setPassword(e.target.value); // Handle event input
    }
  };

  const handleNext = async () => {
    if (!password) {
      alert('Please enter your password');
      return;
    }
    setLoading(true);
    try {
      const data = { email, password };
      const user = await LoginUser(data);
      console.log('Login successful:', user);
      login(user);
      navigate('/dashboard'); 
    } catch (error: any) {
        console.error('Error logging in:', error);
        toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center p-4 bg-white">
        <div className="absolute top-6 flex justify-between items-center w-full px-4">
          <img src={images.leftarrow} alt="Back" className="w-4 h-4 cursor-pointer mt-3" onClick={handleBack} />
          <div className="flex-grow flex justify-center">
            <ProgressBar step={1} color="#0A1E32" />
          </div>
        </div>
        <div className="mt-20 w-11/12 text-left ml-2">
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Great! Now enter your<br/>password
          </h1>
          <p className="text-1xl mb-4" style={{ color: '#0A1E32' }}>
            Please enter your password below to <br/> make sure it's your account.
          </p>
          <InputField
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleChangePassword}
          />
        </div>
        <div className="absolute bottom-9 w-10/12 ml-2">
          <Button onClick={handleNext} disabled={!password || loading}>
            {loading ? 'Loading...' : 'Next'}
          </Button>
        </div>
     </div>
  );
};

export default PasswordScreen;
