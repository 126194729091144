import React from 'react';

type ReferredUserCardProps = {
  name: string;
  donation: string;
  avatarUrl: string;
};

const ReferredUserCard: React.FC<ReferredUserCardProps> = ({ name, donation, avatarUrl }) => {
  return (
    <div className="flex items-center justify-between bg-[#E8ECEE] rounded-xl p-2 w-full">
      <div className="flex items-center">
        <a href="#" className="ml-4 text-black hover:underline">
          {name}
        </a>
      </div>
      <div className="flex items-center space-x-2">
        <div className="flex items-baseline">
          <span className="text-lg text-black font-bold">{donation}</span>
          <span className="text-sm text-black ml-1 mr-2">/month</span>
        </div>
        <div className="flex-shrink-0 w-10 h-10 rounded-full overflow-hidden">
          <img src={avatarUrl} alt="Avatar" className="w-full h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default ReferredUserCard;
