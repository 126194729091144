import { postWithoutToken } from "../request";
import { API_URLS } from "src/constants/apiUrls";

export const signupUser = async (param: any) => {
  try {
    const user = await postWithoutToken(API_URLS.signUp, param);
    return user;
  } catch (error) {
    throw error;
  }
};

export const LoginUser = async (param: any) => {
  try {
    const user = await postWithoutToken(API_URLS.login, param);
    return user;
  } catch (error) {
    throw error;
  }
};

export const CheckUser = async (param: any) => {
  try {
    const response = await postWithoutToken(API_URLS.checkUser, param);
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};

