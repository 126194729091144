import React from 'react';

interface CauseCardProps {
  causeWallPaper: string;
  causeLogo: string;
  causeName: string;
  causeMission: string;
  buyersCount: number;
  onClick: () => void; // Add onClick prop
}

const CauseCard: React.FC<CauseCardProps> = ({
  causeWallPaper,
  causeLogo,
  causeName,
  causeMission,
  buyersCount,
  onClick,
}) => {
  return (
    <div 
      onClick={onClick} // Use onClick prop here
      className="rounded-xl shadow-lg overflow-hidden bg-white h-44 flex relative cursor-pointer" 
      style={{ width: '107%' }}
    >
      <div className="w-1/2 relative">
        <img src={causeWallPaper} alt={causeName} className="w-full h-full object-cover" />
        <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
          <div className="bg-white p-1 rounded-full shadow-md">
            <img src={causeLogo} alt={`${causeName} logo`} className="w-12 h-12 rounded-full" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between w-2/3 p-4">
        <div className="ml-4">
          <h3
            className="text-lg font-bold text-gray-900"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {causeName}
          </h3>
          <p className="text-sm text-gray-500 mt-1 truncate">
            {causeMission}
          </p>
        </div>

        <div className="flex items-center justify-between mt-2 space-x-2">
          <div className="flex items-center">
            <div
              className="flex items-center justify-center text-xs font-medium text-white bg-black rounded-full"
              style={{
                width: '40px',
                height: '40px',
              }}
            >
              {buyersCount}
            </div>
            <span className="ml-1 text-xs text-gray-500">Others are donating</span>
          </div>
          <button
            className="bg-[#F2F2F3] text-black text-xs font-bold py-3 px-2 rounded-xl whitespace-nowrap"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default CauseCard;
