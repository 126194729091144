import React from 'react';

interface ImageContainerProps {
  imageUrl: string;
  logoUrl?: string;
  logoAltText?: string;
  badgeText?: string;
  badgeIconUrl?: string;
  altText: string;
}

const ImageContainer: React.FC<ImageContainerProps> = ({
  imageUrl,
  logoUrl,
  logoAltText,
  badgeText,
  badgeIconUrl,
  altText,
}) => {
  return (
    <div className="relative w-full mb-4 rounded-2xl overflow-hidden aspect-square">
      <img
        src={imageUrl}
        alt={altText}
        className="w-full h-full object-cover"
      />
      {badgeText && (
        <div className="absolute top-2 right-2 p-2 h-10 w-25 rounded-full bg-[#0A1E32] flex items-center bg-opacity-90">
          {badgeIconUrl && (
            <img
              src={badgeIconUrl}
              alt="Badge Icon"
              className="w-6 h-6"
            />
          )}
          <span className="text-2xl text-white ml-2">{badgeText}</span>
        </div>
      )}

      {logoUrl && (
        <div className="absolute bottom-4 right-4 p-1 rounded-full bg-white shadow-md">
          <img
            src={logoUrl}
            alt={logoAltText || `${altText} Logo`}
            className="w-10 h-10 rounded-full object-cover"
          />
        </div>
      )}
    </div>
  );
};

export default ImageContainer;
