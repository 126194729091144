import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import HorizontalScroll from 'src/components/common/horizontalScroll';
import images from 'src/assets/images'; 
import { useAuth } from 'src/contexts/authContext';
import { fetchUserCauses, fetchPopularCauses, fetchCauses } from 'src/services/cause/api';
import UserCauseCard from 'src/components/cause/userCauseCard';
import CauseCard from 'src/components/cause/causeCard';
import SliderWrapper from 'src/components/slider/sliderWrapper';

type Cause = {
  id: string; // Add an ID field for navigation purposes
  name: string;
  mission: string;
  goalAmount: number;
  currentAmount: number;
  causeProfileUrl: string;
  imageUrl: string;
  buyersCount: number;
  profilePictures: string[];
  CategoryName: string;
};

type PopularCause = Cause;

const Causes: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate(); // Initialize the navigate function
  const [selectedCategory, setSelectedCategory] = useState<string>("All Causes");
  const [userCauses, setUserCauses] = useState<Cause[]>([]);
  const [popularCauses, setPopularCauses] = useState<PopularCause[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [causes, setCauses] = useState<Cause[]>([]);

  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    if (category === 'All Causes') {
      if (contentRef.current) {
        contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      const categoryElement = categoryRefs.current[category];
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleCauseClick = (causeId: string) => {
  navigate(`/cause-detail/${causeId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userCausesData: any = await fetchUserCauses({ userId: user.id });
        setUserCauses(userCausesData);

        const popularCausesData: any = await fetchPopularCauses();
        setPopularCauses(popularCausesData);

        const causesData: any = await fetchCauses();
        setCauses(causesData);

        const uniqueCategories: any = [
          ...new Set(causesData.map((cause: Cause) => cause.CategoryName)),
        ];
        setCategories(['All Causes', ...uniqueCategories]);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
        <div className="p-4 mt-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Causes</h1>
            <div className="flex items-center py-1 px-3">
              <img src={images.search_icon_2} alt="Coin Icon" className="w-5 h-5" />
            </div>
          </div>
          <div className="p-4 -mt-2 -ml-4">
            <HorizontalScroll>
              <div className="text-sm flex space-x-3 -ml-2">
                {categories.map((category, index) => (
                  <button 
                    key={index} 
                    onClick={() => handleCategoryClick(category)}
                    className={`py-1 px-4 rounded-full whitespace-nowrap ${
                      selectedCategory === category
                        ? 'bg-[#3D86FF] text-white'
                        : 'bg-[#E5E5E5] text-[#808283]'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </HorizontalScroll>
          </div>
        </div>

        <div ref={contentRef} className="flex-grow px-6 -mt-10 -ml-3 mr-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
          <div className="mt-4 w-full">
            <h2 className="text-lg font-bold text-gray-900 ml-2">My Causes</h2>
            <div style={{ position: 'relative', borderRadius: '12px', width: '105%', marginLeft: '4px' }}>
              {userCauses.length > 0 ? (
                <SliderWrapper
                  items={userCauses}
                  renderItem={(userCause, index) => (
                    <UserCauseCard
                      key={index}
                      causeWallPaper={userCause.imageUrl}
                      causeLogo={userCause.causeProfileUrl}
                      causeName={userCause.name}
                      causeMission={userCause.mission}  
                      raisedAmount={userCause.currentAmount}
                      goalAmount={userCause.goalAmount}
                      buyersCount={userCause.buyersCount || 0}
                      buyersImages={userCause.profilePictures || []}
                      onClick={() => handleCauseClick(userCause.id)} // Pass click handler
                    />
                  )}
                />
              ) : (
                <p className="text-center text-[#787B7E] mt-10">No user causes available</p>
              )}
            </div>
          </div>

          <div className="mt-12 w-full">
            <h2 className="text-lg font-bold text-gray-900 ml-2">Popular Causes</h2>
            <div className="grid gap-4 mt-4">
              {popularCauses.length > 0 ? (
                popularCauses.map((popularCause, index) => (
                  <CauseCard
                    key={index}
                    causeWallPaper={popularCause.imageUrl}
                    causeLogo={popularCause.causeProfileUrl}
                    causeName={popularCause.name}
                    causeMission={popularCause.mission}
                    buyersCount={popularCause.buyersCount || 0}
                    onClick={() => handleCauseClick(popularCause.id)} // Pass click handler
                  />
                ))
              ) : (
                <p className="text-center text-[#787B7E] mt-10">No popular causes available</p>
              )}
            </div>
          </div>

          {categories.slice(1).map((category) => (
            <div key={category} ref={(el) => (categoryRefs.current[category] = el)} className="mt-12 w-full">
              <h2 className="text-lg font-bold text-gray-900 ml-2">{category}</h2>
              <div className="grid gap-4 mt-4">
                {causes
                  .filter((cause) => cause.CategoryName === category)
                  .map((cause, index) => (
                    <CauseCard
                      key={index}
                      causeWallPaper={cause.imageUrl}
                      causeLogo={cause.causeProfileUrl}
                      causeName={cause.name}
                      causeMission={cause.mission}
                      buyersCount={cause.buyersCount || 0}
                      onClick={() => handleCauseClick(cause.id)} // Pass click handler
                    />
                  ))}
              </div>
            </div>
          ))}

          <div className="mt-12 w-full">
            <div className="w-full h-40 bg-gray-100 rounded-lg"></div> 
          </div>
        </div>

        <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
          <BottomTabsBar />
        </div>
    </>
  );
};

export default Causes;
