import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkCheckoutSession } from 'src/services/payment/api'; 
import images from 'src/assets/images';
const PaymentSuccess: React.FC = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const response: any = await checkCheckoutSession({ sessionId });
        if (response.status === 'Payment successful' || 'Subscription active') {
          console.log('Payment was successful:', response);
          setTimeout(() => {
            navigate('/onboarding-complete');
          }, 2000);
        } else {
          console.warn('Payment is pending or failed:', response.session);
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
      }
    };

    if (sessionId) {
      checkPaymentStatus(); 
    }
  }, [sessionId, navigate]);

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-white pt-16">
      <div className="relative text-center mt-36">
        <div className="flex items-center justify-center">
          <div className="circle-animation"></div>
          <div className="circle-animation delay-1"></div>
          <div className="circle-animation delay-2"></div>
          <img src={images.success_icon_2} alt="Success" className="w-32 h-32 mx-auto mb-4" />
        </div>
        <h2 className="text-3xl font-semibold text-[#FF6B6B] mb-2 relative z-10">
          Success
        </h2>
        <p className="text-lg text-gray-700 mb-8 relative z-10">
          Payment Complete
        </p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
