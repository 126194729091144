import React from 'react';
import { ToastContainer } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes/route';
import './App.css';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || "0");

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <div>
        <Routes />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Elements>
  );
};

export default App;
