import React, { useState, useEffect } from 'react';
import images from 'src/assets/images';
import ProgressBar from 'src/components/common/progressBar';
import { useNavigate } from 'react-router-dom';
import CauseCard from 'src/components/selectcause/causeCard';
import CauseSelectSuccess from './causeSelectSuccess';
import { fetchCauses } from 'src/services/cause/api';
import { useAuth } from 'src/contexts/authContext';

type Cause = {
  id: string;
  name: string;
  mission: string;
  goalAmount: number;
  currentAmount: number;
  causeProfileUrl: string;
  imageUrl: string;
  buyersCount: number;
  profilePictures: string[];
  CategoryName: string;
  isFeatured: boolean;
};

const SelectCauseTwo: React.FC = () => {
  const navigate = useNavigate();
  const { setSelectedCauseId } = useAuth(); // Use the context to set the selected cause ID
  const [causes, setCauses] = useState<Cause[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false); // State to manage the success component visibility

  const handleBack = () => {
    navigate('/select-cause');
  };

  const handleSupportCause = (causeId: string) => {
    setSelectedCauseId(causeId); // Save the selected cause ID in the context
    setShowSuccess(true); 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const causesData: any = await fetchCauses();
        setCauses(causesData);
      } catch (error) {
        console.error('Failed to fetch causes:', error);
      }
    };

    fetchData();
  }, []);

  const filteredCauses = causes.filter(cause =>
    cause.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const featuredCause = filteredCauses.find(cause => cause.isFeatured);
  const otherCauses = filteredCauses.filter(cause => !cause.isFeatured);

  return (
    <>
      <div className="p-4">
        <div className="absolute top-6 flex justify-between items-center w-full px-4">
          <img
            src={images.leftarrow}
            alt="Back"
            className="w-4 h-4 cursor-pointer mt-3"
            onClick={handleBack}
          />
          <div className="flex-grow flex justify-center">
            <ProgressBar step={0} color="#0A1E32" />
          </div>
        </div>

        <div className="mt-16 w-full max-w-md text-left">
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Choose Your Cause
          </h1>
          <p className="text-xl mb-4 text-[#1E1E1E]">
            Select the cause you would like to support.
            <span className="text-[#1E1E1E] opacity-60">
              (Don't worry, you can change this later)
            </span>
          </p>
        </div>

        <div className="mt-4 relative">
          <input
            type="text"
            placeholder="Search all causes"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full py-3 px-4 rounded-3xl bg-[#F2F2F3] text-[#1E1E1E] border-2 border-transparent outline-none focus:border-[#3D86FF] pl-10"
          />
          <img
            src={images.search_icon_3} 
            alt="Search"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
          />
        </div>
      </div>

      <div className="flex-grow px-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
        <div className="mt-4 space-y-4">
          {featuredCause && (
            <div className="border-2 border-[#3D86FF] rounded-lg p-4">
              <p className="text-white text-sm bg-[#3D86FF] inline-block px-2 mb-2 rounded-2xl">
                RECOMMENDED CHOICE
              </p>
              <CauseCard
                causeLogo={featuredCause.causeProfileUrl}
                causeName={featuredCause.name}
                buyersCount={featuredCause.buyersCount || 0}
                currentAmount={featuredCause.currentAmount.toFixed()}
                goalAmount={featuredCause.goalAmount.toFixed(2)}
                onClick={() => handleSupportCause(featuredCause.id)} // Pass the cause ID
              />
            </div>
          )}

          <div className="mt-10">
            <h2 className="text-lg font-bold text-gray-900 ml-2">All Causes</h2>
          </div>

          {otherCauses.length > 0 ? (
            otherCauses.map((cause) => (
              <CauseCard
                key={cause.id}
                causeLogo={cause.causeProfileUrl}
                causeName={cause.name}
                buyersCount={cause.buyersCount || 0}
                currentAmount={cause.currentAmount.toFixed()}
                goalAmount={cause.goalAmount.toFixed(2)}
                onClick={() => handleSupportCause(cause.id)} // Pass the cause ID
              />
            ))
          ) : (
            <p className="text-center text-[#787B7E] mt-10">No causes available</p>
          )}
        </div>
      </div>

      {showSuccess && <CauseSelectSuccess onClose={() => setShowSuccess(false)} />} 
    </>
  );
};

export default SelectCauseTwo;
