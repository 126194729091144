
import React, { useEffect } from 'react';
import images from 'src/assets/images';

interface WelcomeBackScreenProps {
  onClose: () => void;
}

const WelcomeBackScreen: React.FC<WelcomeBackScreenProps> = ({ onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(); 
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className='w-full'>
      <div className="absolute inset-0 z-40" style={{ backdropFilter: 'blur(2px)' }} />
      <div className="absolute inset-x-0 top-20 flex flex-col items-center justify-center bg-[#0A1E32] bg-opacity-90 rounded-t-3xl z-50 h-[calc(100%-5rem)] sm:mx-6 md:mx-8 lg:mx-12">
        <div className="text-center z-10">
          <img
            src={images.welcome_screen_icon}
            alt="Waving Hand"
            className="mx-auto w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 -mt-32"
          />
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mt-4 sm:mt-6 md:mt-8">
            Welcome Back
          </h1>
          <p className="text-sm sm:text-base md:text-lg text-white mt-4 sm:mt-6 md:mt-8">
            You have successfully logged back in! <br/> We're glad to see you again!
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBackScreen;
