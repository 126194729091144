import React, { useState, useEffect } from 'react';
import images from 'src/assets/images';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import { fetchCauses } from 'src/services/cause/api';
import DonationOptionCard from 'src/components/chooseDonation/donationOptionCard';
import CustomDonation from './customDonation';
import AppDonation from './appDonation'; // Import the AppDonation component
import { useAuth } from 'src/contexts/authContext';

const ChooseDonation: React.FC = () => {
  const navigate = useNavigate();
  const { setDonationAmount, donationAmount } = useAuth(); // Use the context to set and get the donation amount
  const proportionalAmount: number = parseFloat(process.env.REACT_APP_CAUSE_COIN_PROPORTIONAL_AMOUNT || "0");

  const [showCustomDonation, setShowCustomDonation] = useState(false);
  const [showAppDonation, setShowAppDonation] = useState(false); // New state for app donation

  const handleBack = () => {
    navigate(-1);
  };

  const handleSelectCustomAmount = () => {
    setShowCustomDonation(true);
  };

  const handleCloseCustomDonation = () => {
    setShowCustomDonation(false);
    if (donationAmount) {
      setShowAppDonation(true); // Show AppDonation only if donationAmount is set
    }
  };

  const handleDonationComplete = (amount: number) => {
    setDonationAmount(amount); // Store the selected donation amount in context
    setShowAppDonation(true); // Show AppDonation pop-up after selecting a donation
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const causesData: any = await fetchCauses();
        console.log('Cause data', causesData);
      } catch (error) {
        console.error('Failed to fetch causes:', error);
      }
    };

    fetchData();
  }, []);

  // Define donation options array
  const donationOptions = [
    { amount: 5, label: "That's like getting 1 coffee", coins: 5 * proportionalAmount },
    { amount: 10, label: "That's like getting 2 coffees", coins: 10 * proportionalAmount },
    { amount: 20, label: "That's like getting 4 coffees", coins: 20 * proportionalAmount },
    { amount: 50, label: "That's like getting 10 coffees", coins: 50 * proportionalAmount },
  ];

  return (
    <>
      <div className="p-4 relative">
        <div className="absolute top-6 flex justify-between items-center w-full px-4">
          <img
            src={images.leftarrow}
            alt="Back"
            className="w-4 h-4 cursor-pointer mt-3"
            onClick={handleBack}
          />
          <div className="flex-grow flex justify-center">
            <ProgressBar step={1} color="#0A1E32" />
          </div>
        </div>

        <div className="mt-16 w-full max-w-md text-left">
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Awesome! Now pick a <br/> donation amount
          </h1>
          <p className="text-md mb-4 text-[#1E1E1E]">
            Decide how much you want to give each month. For every $1 you donate, we will give you {proportionalAmount} fundcoins.
          </p>
        </div>

        <div className="flex-grow space-y-2 px-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
          {/* Map over the donationOptions array to render DonationOptionCard components */}
          {donationOptions.map((option, index) => (
            <DonationOptionCard
              key={index}
              amount={option.amount}
              label={option.label}
              coins={option.coins}
              checked={donationAmount === option.amount}
              onCheckChange={() => handleDonationComplete(option.amount)} // Pass the amount when a donation is selected
            />
          ))}
          
          <div className="mt-4 space-y-4">
            <div className="text-center mt-6">
              <p className="text-sm text-[#8197AD]">OR</p>
              <button className="text-[#3D86FF] mt-2" onClick={handleSelectCustomAmount}>
                Set a custom amount
              </button>
            </div>
          </div>
        </div>

        {showCustomDonation && (
          <CustomDonation onClose={handleCloseCustomDonation} onComplete={handleDonationComplete} /> // Pass handleDonationComplete to handle custom amounts
        )}

        {showAppDonation && (
          <AppDonation onClose={() => setShowAppDonation(false)} /> // Render AppDonation pop-up
        )}
      </div>
    </>
  );
};

export default ChooseDonation;
