import { post } from "../request";
import { API_URLS } from "src/constants/apiUrls";

export const createCheckoutSession = async (param: any) => {
  try {
    const data = await post(API_URLS.setupPaymentIntent, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkCheckoutSession = async (param: any) => {
  try {
    const data = await post(API_URLS.checkStripeCheckoutSession, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkStripePaymentMethod = async (param: any) => {
  try {
    const data = await post(API_URLS.checkStripePaymentMethod, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createCauseSubscription = async (param: any) => {
  try {
    const data = await post(API_URLS.createCauseSubscription, param);
    return data;
  } catch (error) {
    throw error;
  }
};
