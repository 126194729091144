import React from 'react';
import images from 'src/assets/images'; 
import { useNavigate } from 'react-router-dom';


const IntroScreenOne: React.FC = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/introduction-two');
  };

  return (
      <div className="relative w-full h-full flex flex-col items-center justify-center p-4 bg-darkblue">
        <div className="absolute inset-0 bg-cover bg-center rounded-3xl" style={{ backgroundImage: `url(${images.intro_one})` }}></div>
        <div className="absolute bottom-8 z-10 flex flex-col items-center text-center px-6">
          <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            We Believe Good People Deserve<br /><span className="block">To Be Rewarded...</span>
          </h1>
          <p className="text-s mb-10" style={{ color: '#8197AD' }}>
            Join our community of fundr’s who are changing the world and getting rewarded<br /><span className="block">for their donations.</span>
          </p>  
          <button className="relative py-0.5 px-0.5 rounded-custom text-white bg-transparent border-none mt-8 w-full max-w-md" onClick={handleNext}>
            <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-orange-400 rounded-custom p-px"></span>
            <span className="relative bg-darkblue rounded-custom block py-3 px-12 font-bold text-lg">Next</span>
          </button>
        </div>
    </div>
  );
};

export default IntroScreenOne;
