import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import Button from 'src/components/common/button';
import { InputField } from 'src/components/common/inputField';
import images from 'src/assets/images';
import useRedirectIfNoState from 'src/hooks/redirectHook';
import { toast } from 'react-toastify';

const NameInputScreen: React.FC = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state as { email: string } || {};
  useRedirectIfNoState('email');

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    if (storedName) {
      setName(storedName);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === 'string') {
      setName(e); 
    } else {
      setName(e.target.value); 
    }
  };

  const handleNext = () => {
    const nameParts = name.trim().split(' ');
    if (nameParts.length < 2) {
      toast.error('Please enter both your first and last name.');
      return;
    }
    
    localStorage.setItem('name', name);

    navigate('/phone-password', { state: { email, name } });
  };

  const handleBack = () => {
    navigate('/email');
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center p-4 bg-white">
        <div className="absolute top-6 flex justify-between items-center w-full px-4">
          <img src={images.leftarrow} alt="Back" className="w-4 h-4 cursor-pointer mt-3" onClick={handleBack} />
          <div className="flex-grow flex justify-center">
            <ProgressBar step={0} color="#0A1E32" />
          </div>
        </div>
        <div className="mt-20 w-11/12 text-left ml-2">
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Looks like you're new!
          </h1>
          <p className="text-1xl mb-4" style={{ color: '#0A1E32' }}>
            What is your first and last name?
          </p>
          <InputField
            type="text"
            placeholder="First and Last name"
            value={name}
            onChange={handleChange}
          />
        </div>
        <div className="absolute bottom-9 w-10/12 ml-2">
          <Button onClick={handleNext} disabled={!name}>
            Next
          </Button>
        </div>
    </div>
  );
};

export default NameInputScreen;
