import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/contexts/authContext';
import Loader from 'src/components/common/loader';

interface ProtectedRouteProps {
  element: React.ReactElement;
  isNewUserRoute?: boolean;
  allowBothUserTypes?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, isNewUserRoute, allowBothUserTypes }) => {
  const { isAuthenticated, isNewUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      await new Promise(resolve => setTimeout(resolve, 100));
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/email" state={{ from: location }} />;
  }

  if (allowBothUserTypes) {
    return element;
  }

  if (isNewUserRoute && !isNewUser) {
    return <Navigate to="/dashboard" />;
  }

  if (!isNewUserRoute && isNewUser) {
    return <Navigate to="/congratulations" />;
  }

  return element;
};

export default ProtectedRoute;
