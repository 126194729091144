import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageContainer from 'src/components/common/imageContainer';
import images from 'src/assets/images';
import TravelInfoCard from 'src/components/reward/travelInfoCard';
import RedemptionFeesCard from 'src/components/reward/redemptionFeesCard';
import StepList from 'src/components/reward/stepList';
import TestimonialCard from 'src/components/reward/testimonialCard';
import FundcoinPurchaseCard from 'src/components/reward/FundcoinPurchaseCard';
import { fetchReward } from 'src/services/reward/api';
import { useAuth } from 'src/contexts/authContext';
const RewardDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [reward, setReward] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [fundCoinBalance, setFundCoinBalance] = useState(0);
  const { user } = useAuth();
  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = async () => {
    // Purchase logic here
  };

  useEffect(() => {
    const fetchRewardDetails = async () => {
      try {
        setLoading(true);
        setError(null);

        const data: any = await fetchReward({ id: id });
        setReward(data);
        setFundCoinBalance(user.fundcoinBalance || 0);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRewardDetails();
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!reward) {
    return <p>No Reward details found</p>;
  }

  const travelItems = [
    "Travel 7 Days A Week",
    "Online Reservation System + Phone Bookings",
    "Over 3500 Locations Worldwide To Choose From",
    "Good For Two Adults & Two Guests",
    "Pay Just Taxes & Fees",
    "Just A 30 Days Advance Notice Required",
    "You Will Have 24 Months To Complete Travel",
  ];

    const steps = [
    {
      number: 1,
      title: 'Activate Now',
      description: 'You can start planning your trip right away or later. You have 24 months to complete the travel offer after activation.',
    },
    {
      number: 2,
      title: 'Start Planning Your Trip',
      description: 'Choose your destinations, accommodations and dates with a live travel agent.',
    },
    {
      number: 3,
      title: 'Booking Confirmation',
      description: 'Once your reservation is booked you will receive your booking confirmation details.',
    },
    {
      number: 4,
      title: 'Enjoy Your Vacation',
      description: 'Relax and enjoy your vacation as a thank you for your generous donations to your cause.',
    },
  ];
  return (
    <>
      <div className="flex flex-col items-center w-full max-w-2xl mx-auto px-4 sm:px-6 mt-10 sm:mt-6 flex-grow overflow-x-hidden scrollbar-hide">
        <button
          onClick={handleBack}
          className="absolute top-12 left-6 z-20 bg-white  text-gray-900 p-2 rounded-full shadow-md hover:bg-gray-200 focus:outline-none"
        >
          <img src={images.leftarrow} alt="Back" className="w-6 h-6" />
        </button>

        <div className="flex-grow overflow-y-auto w-full scrollbar-hide">
          <ImageContainer
            imageUrl={reward.imageUrl}
            badgeText={reward.fundcoinCost}
            badgeIconUrl={images.fund_coin_icon}
            altText="Example Image"
          />

          <div className="w-full">
            <h3
              className="text-sm font-bold mb-2"
              style={{
                background: 'linear-gradient(to bottom, #EC4899, #FB923C)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {reward.CategoryName || "Category Name"}
            </h3>
            <h2 className="text-2xl font-bold text-gray-900">{reward.name || "Reward Name"}</h2>
            <p className="text-black font-normal">  
              {reward.description || "Reward Description"}.
            </p>

            <div className="flex items-center justify-between mt-6 space-x-2">
              <div className="flex -space-x-2">
                {reward.profilePictures && reward.profilePictures.slice(0, 3).map((image: any, index: number) => (
                  <img key={index} src={image} alt={`Donor ${index + 1}`} className="w-9 h-9 rounded-full border-2 border-white" />
                ))}
                <div className="flex items-center">
                  <div className="flex items-center justify-center text-xs font-medium text-white bg-black w-8 h-8 rounded-full">
                    {reward.buyersCount || 0}
                  </div>
                  <span className="ml-2 text-xs text-gray-500">Others <br/> bought this</span>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-10'>
            <h2 className="text-lg font-semibold text-black mb-2">Travel Info:</h2>
            <TravelInfoCard items={travelItems} />
          </div>

         <h2 className="text-lg font-bold text-gray-900 mt-8">Redemption Fees</h2>
          <RedemptionFeesCard 
            redemptionFee="$19.95" 
            taxesAndFees="$120.00" 
            totalCost="$139.95" 
          />
         
        <h2 className="text-lg font-bold text-gray-900 mt-8 mb-4">How it Works:</h2>
        <StepList steps={steps} />

         <h2 className="text-lg font-bold text-gray-900 mt-8 mb-4">Testimonials:</h2>         
        <TestimonialCard
        rating={4}
        text="“Our honeymoon was amazing and relaxing. So happy we were able to go right away after our wedding. Our hotel was amazing and we enjoyed walking along the beach each morning with our coffee.”"
        author="Natalie L."
        location="Littleton, CO"
      />
          <div className="mt-12 w-full">
            <div className="w-full h-40 bg-gray-100 rounded-lg"></div> 
          </div>
        </div>      
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto bg-white">
          <FundcoinPurchaseCard coinAmount={fundCoinBalance} onBuyNow={handleNext} />
      </div>
    </>
  );
};

export default RewardDetail;
