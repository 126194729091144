import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC52G16f4v1MOxnxI4Cd_Qf8hKoRyNH5mQ",
  authDomain: "fundr-c79e1.firebaseapp.com",
  projectId: "fundr-c79e1",
  storageBucket: "fundr-c79e1.appspot.com",
  messagingSenderId: "619794887374",
  appId: "1:619794887374:web:ef47697adc9ffeffb8d69f",
  measurementId: "G-GV0R5H6CYP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.useDeviceLanguage();
export { app, auth, RecaptchaVerifier, signInWithPhoneNumber };
