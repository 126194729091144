import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'src/components/common/button';
import ImageContainer from 'src/components/common/imageContainer';
import AboutUsSection from 'src/components/common/aboutUs';
import LineProgressBar from 'src/components/cause/lineProgressBar';
import images from 'src/assets/images';
import { fetchCause, fetchUserCauses, cancelCauseSubscription } from 'src/services/cause/api';
import { useAuth } from 'src/contexts/authContext';

const CauseDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [cause, setCause] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { user, setSelectedCauseId, setDonationAmount } = useAuth(); // Get setDonationAmount from AuthContext

  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = async () => {
    if (isSubscribed) {
      try {
        // Cancel the subscription
        await cancelCauseSubscription({ userId: user.id, causeId: parseInt(id || '', 10) });
        setIsSubscribed(false); // Update the state after successful cancellation
        toast.success("Subscription cancelled successfully");
         setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } catch (error) {
        console.error("Error cancelling the subscription:", error);
        toast.error("Failed to cancel the subscription.");
      }
    } else {
      // Reset the donation amount
      setDonationAmount(0); // Reset the donation amount to empty
      // Update the state with the causeId and navigate to the donation screen
      setSelectedCauseId(id ? id.toString() : '');
      navigate('/choose-donation-amount'); // Adjust the path as per your routing
    }
  };

  useEffect(() => {
    const fetchCauseDetails = async () => {
      try {
        setLoading(true);
        setError(null);

        const causeData: any = await fetchCause({ id: id });
        setCause(causeData);

        if (user) {
          // Fetch user's subscribed causes and check if the current cause is among them
          const userCauses: any = await fetchUserCauses({ userId: user.id });
          const subscribedCause = userCauses.find((userCause: any) => userCause.id === parseInt(id || '', 10));

          setIsSubscribed(!!subscribedCause);
        }
      } catch (err: any) {
        setError(err.message);
        toast.error("Error fetching cause details.");
      } finally {
        setLoading(false);
      }
    };

    fetchCauseDetails();
  }, [id, user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!cause) {
    return <p>No cause details found</p>;
  }

  const goalAmount = cause.goalAmount || 0;
  const currentAmount = cause.currentAmount || 0;
  const percentage = goalAmount > 0 ? (currentAmount / goalAmount) * 100 : 0;

  return (
    <>
      <div className="flex flex-col items-center w-full max-w-2xl mx-auto px-4 sm:px-6 mt-10 sm:mt-6 flex-grow overflow-x-hidden scrollbar-hide">
        <button
          onClick={handleBack}
          className="absolute top-12 left-6 z-20 bg-white bg-opacity-30 text-gray-900 p-2 rounded-full shadow-md hover:bg-gray-200 focus:outline-none"
        >
          <img src={images.leftarrow} alt="Back" className="w-6 h-6" />
        </button>

        <div className="flex-grow overflow-y-auto w-full scrollbar-hide">
          <ImageContainer
            imageUrl={cause.imageUrl || images.cause_wallpaper} 
            logoUrl={cause.logoUrl || images.cause_logo}
            altText={cause.name || "Cause Logo"}
          />

          <div className="w-full">
            <h2 className="text-2xl font-bold text-gray-900">{cause.name || "Cause Name"}</h2>
            <p className="text-sm font-bold text-[#3D86FF]">Mission: 
              <span className="text-black font-normal"> {cause.mission || "Cause Mission"}.</span>
            </p>

            <div className="mt-4">
              <LineProgressBar percentage={percentage} />
              <p className='mt-4'>
                <strong className="text-transparent text-lg bg-clip-text"
                style={{
                  background: 'linear-gradient(to right, #EC4899, #FB923C)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                  {`$${currentAmount}.00`}
                </strong>{' '}
                <span className="text-[#787B7E]">raised out of </span>
                <strong className="text-[#787B7E] text-lg">{`$${goalAmount}`}</strong>        
              </p>
            </div>

            <div className="flex items-center justify-between mt-6 space-x-2">
              <div className="flex -space-x-2">
                {cause.profilePictures && cause.profilePictures.slice(0, 3).map((image: any, index: number) => (
                  <img key={index} src={image} alt={`Donor ${index + 1}`} className="w-9 h-9 rounded-full border-2 border-white" />
                ))}
                <div className="flex items-center">
                  <div className="flex items-center justify-center text-xs font-medium text-white bg-black w-8 h-8 rounded-full">
                    {cause.buyersCount || 0}
                  </div>
                  <span className="ml-2 text-xs text-gray-500">Others are donating</span>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-10'>
            <AboutUsSection content={cause.description} />
          </div>
          <div className="mt-12 w-full">
            <div className="w-full h-40 bg-gray-100 rounded-lg"></div> 
          </div>
        </div>      
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto px-4 py-4 bg-white">
        <div className="flex justify-center">
          <Button onClick={handleNext}>
            {isSubscribed ? 'CANCEL SUBSCRIPTION' : 'DONATE TO THIS CAUSE'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CauseDetail;
