import React from 'react';

interface TestimonialCardProps {
  rating: number;
  text: string;
  author: string;
  location: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ rating, text, author, location }) => {
  const gradientId = "star-gradient";

  const stars = Array(5).fill(0).map((_, index) => (
    <svg
      key={index}
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill={index < rating ? `url(#${gradientId})` : 'gray'}
    >
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#FB923C', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.4 4.304a1 1 0 00.95.69h4.516c.969 0 1.371 1.24.588 1.81l-3.676 2.668a1 1 0 00-.364 1.118l1.4 4.304c.3.921-.755 1.688-1.54 1.118L10 14.88l-3.676 2.667c-.784.57-1.838-.197-1.539-1.118l1.4-4.304a1 1 0 00-.364-1.118L2.145 9.73c-.783-.57-.38-1.81.588-1.81h4.516a1 1 0 00.95-.69l1.4-4.304z" />
    </svg>
  ));

  return (
    <div className="bg-[#F4F4F4] rounded-2xl shadow-md p-4 mb-6 w-full">
      <div className="flex items-center mb-2">
        {stars}
      </div>
      <p className="text-md text-black mt-2">{text}</p>
      <div className="mt-4">
        <p className="text-sm font-bold text-gray-900">{author}</p>
        <p className="text-xs text-gray-500">{location}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
