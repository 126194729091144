// src/context/RouterProvider.tsx
import React, { ReactNode } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { AuthProvider } from 'src/contexts/authContext';

interface RouterProviderProps {
  children: ReactNode;
}

const RouterProvider: React.FC<RouterProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <AuthProvider navigate={navigate}>
        {children}
      </AuthProvider>
  );
};

const RouterWrapper: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Router>
    <RouterProvider>{children}</RouterProvider>
  </Router>
);

export default RouterWrapper;
