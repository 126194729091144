import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import images from 'src/assets/images';

interface CauseSelectSuccessProps {
  onClose: () => void;
}

const CauseSelectSuccess: React.FC<CauseSelectSuccessProps> = ({ onClose }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/choose-donation-amount');
      setTimeout(() => onClose(), 100); 
    }, 3000); 

    return () => clearTimeout(timer); 
  }, [onClose, navigate]);

  const handleImmediateNavigation = () => {
    navigate('/choose-donation-amount');
    onClose(); 
  };

  return (
    <div className="w-full" onClick={handleImmediateNavigation}>
      <div className="absolute inset-0 z-10 bg-[#0A1E32] bg-opacity-80 max-w-[548px] mx-auto" style={{ backdropFilter: 'blur(2px)' }} />
      <div className="fixed inset-x-0 bottom-0 flex flex-col items-center justify-center bg-white rounded-t-3xl z-40 h-[calc(50%-5rem)] max-w-[548px] w-full mx-auto p-6">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img
            src={images.success_icon}
            alt="Success"
            className="w-20 h-20"
          />
          <h1 className="text-3xl font-bold text-gray-900 mt-4">
            Awesome
          </h1>
          <p className="text-lg text-black mt-4 text-center">
            You have successfully selected a cause. <br /> 
            Next, let's choose how much you'd like <br /> 
            to donate.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CauseSelectSuccess;
