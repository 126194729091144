import React, { useState } from 'react';
import images from 'src/assets/images';
import Button from 'src/components/common/button';
import LineProgressBar from 'src/components/cause/lineProgressBar';
import CauseSelectSuccess from './causeSelectSuccess'; // Import the CauseSelectSuccess component

interface CauseDetailProps {
  onClose: () => void;
  cause: {
    id: string;
    name: string;
    mission: string;
    goalAmount: number;
    currentAmount: number;
    causeProfileUrl: string;
    imageUrl: string;
    buyersCount: number;
  };
}

const CauseDetail: React.FC<CauseDetailProps> = ({ onClose, cause }) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false); // State to manage the success component visibility

  const percentage = cause.goalAmount
    ? (cause.currentAmount / cause.goalAmount) * 100
    : 0;

  return (
    <>
      <div className='w-full'>
        <div className="absolute inset-0 z-40 bg-[#0A1E32] bg-opacity-80" style={{ backdropFilter: 'blur(2px)' }} />
        <div className="absolute inset-x-0 top-20 flex flex-col items-center bg-white rounded-t-3xl z-50 h-[calc(100%-5rem)] sm:mx-6 md:mx-8 lg:mx-12 max-w-screen-lg mx-auto">
          <div className="relative w-full sm:h-80 md:h-96 lg:h-104 rounded-t-3xl overflow-hidden">
            <img
              src={cause.imageUrl}
              alt={cause.name}
              className="w-full h-full object-cover"
            />
            <button className="absolute top-6 right-6" onClick={onClose}>
              <img src={images.close_icon} alt="Close" className="w-6 h-6" />
            </button>
          </div>
          
          <div className="flex items-start p-6 w-full max-w-md">
            <div className='px-2 py-4'>          
              <img
                src={cause.causeProfileUrl}
                alt={`${cause.name} logo`}
                className="w-14 h-14 rounded-full mr-4"
              />
            </div>
            <div className="flex-grow">
              <h3 className="text-2xl font-bold text-gray-900">{cause.name}</h3>
              <p className="text-md text-[#0A1E32] opacity-60">{cause.buyersCount} funders</p>
              <div className="mt-2">
                <LineProgressBar percentage={percentage} height='4px' />
                <p className='mt-3 text-sm text-black'>
                  <strong className="text-black font-bold">{`$${cause.currentAmount.toFixed(2)}`}</strong>{' '}
                  <span className="text-[#8298AE]">raised out of </span>
                  <strong className="text-[#8298AE]">{`$${cause.goalAmount.toFixed(2)}`}</strong>
                </p>            
              </div>
            </div>      
          </div>
          <p className="mt-4 ml-4 text-black">
            <span className="font-bold text-[#3D86FF]">Mission: </span>
            {cause.mission}
          </p>
        </div>
      </div>

      {showSuccess && <CauseSelectSuccess onClose={() => setShowSuccess(false)} />} {/* Show the success screen */}
    </>
  );
};

export default CauseDetail;
