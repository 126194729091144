import React, { useState, useEffect } from 'react';
import TopBar from 'src/components/dashboard/topbar';
import DonationProgressBar from 'src/components/dashboard/donationProgressBar';
import FundcoinBalanceCard from 'src/components/dashboard/fundBalanceCard';
import ReferCard from 'src/components/dashboard/referCard';
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import HorizontalScroll from 'src/components/common/horizontalScroll';
import UserRewardCard from 'src/components/dashboard/userRewardCard';
import PopularRewardCard from 'src/components/reward/popularRewardCard';
import SliderWrapper from 'src/components/slider/sliderWrapper';
import WelcomeBackScreen from './welcomeBack';
import { fetchUserProfile, fetchPopularRewards } from 'src/services/dashboard/api';
import { useAuth } from 'src/contexts/authContext';
import UserCauseCard from 'src/components/dashboard/userCauseCard';
import { useNavigate, useLocation } from 'react-router-dom';

type Cause = {
  id: string;
  name: string;
  goalAmount: number;
  currentAmount: number;
  causeProfileUrl: string;
  imageUrl: string;
};

type Reward = {
  name: string;
  fundcoinCost: number;
  imageUrl: string;
  RewardCategory: {
    name: string;
  };
};

type PopularReward = {
  name: string;
  fundcoinCost: number;
  imageUrl: string;
  rewardId: number;
  CategoryName: string;
  buyersCount: number;
  profilePictures: string[];
};

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, updateUser } = useAuth(); 
  const [showWelcomeBack, setShowWelcomeBack] = useState(false);
  const [causes, setCauses] = useState<Cause[]>([]);
  const [totalDonations, setTotalDonations] = useState(0);
  const [fundCoinBalance, setFundCoinBalance] = useState(0);
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [popularRewards, setPopularRewards] = useState<PopularReward[]>([]);

  const fetchData = async () => {
    try {
      const userProfileData: any = await fetchUserProfile({ userId: user.id });
      await updateUser({ userId: user.id });
      
      setCauses(userProfileData.causes || []);
      setTotalDonations(userProfileData.totalDonations || 0);
      setFundCoinBalance(user.fundcoinBalance || 0);
      setRewards(userProfileData.rewards || []);
      
      const popularRewardsData: any = await fetchPopularRewards();
      setPopularRewards(popularRewardsData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');

    if (!hasSeenWelcome) {
      setShowWelcomeBack(true);
      localStorage.setItem('hasSeenWelcome', 'true');
    }

    fetchData(); 
  }, [user.id, location]);

  const handleCauseClick = (causeId: string) => {
    navigate(`/cause-detail/${causeId}`);
  };

  const handleRewardClick = (rewardId: string) => {
    navigate(`/reward-detail/${rewardId}`);
  };

  return (
    <>
      <div className="fixed top-0 z-10 w-full max-w-[548px] mx-auto">
        <TopBar profileImage={user.profileUrl} />
      </div>

      <div className="flex flex-col items-center justify-start px-4 sm:px-6 mt-20 sm:mt-6 flex-grow overflow-y-auto overflow-x-hidden scrollbar-hide w-full">
        <DonationProgressBar currentAmount={totalDonations} yearlyGoal={user.yearlyGoal} />
        <div className="-mt-4 space-y-4 flex flex-col items-center w-full">
          <FundcoinBalanceCard balance={fundCoinBalance} />
          <div className="mt-2 w-full">
            <ReferCard />
          </div>
        </div>

        <div className="mt-4 w-full">
          <h2 className="text-lg font-semibold text-gray-900 ml-2">My Causes</h2>
          {causes.length > 0 ? (
            <HorizontalScroll>
              {causes.map((cause, index) => (
                <UserCauseCard
                  key={index}
                  causeWallPaper={cause.imageUrl}
                  causeLogo={cause.causeProfileUrl}
                  causeName={cause.name}
                  raisedAmount={cause.currentAmount}
                  goalAmount={cause.goalAmount}
                  onClick={() => handleCauseClick(cause.id)}
                />
              ))}
            </HorizontalScroll>
          ) : (
            <p className="text-center text-[#787B7E] mt-10">No causes available</p>
          )}
        </div>

        <div className="mt-4 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">My Rewards</h2>
          <div className="relative rounded-xl w-full">
            {rewards.length > 0 ? (
              <SliderWrapper
                items={rewards}
                renderItem={(reward, index) => (
                  <UserRewardCard
                    key={index}
                    imageUrl={reward.imageUrl}
                    category={reward.RewardCategory.name}
                    title={reward.name}
                    onClick={() => handleRewardClick(reward.id)}
                  />
                )}
              />
            ) : (
              <p className="text-center text-[#787B7E] mt-10">No rewards available</p>
            )}
          </div>
        </div>

        <div className="mt-12 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">Popular Rewards</h2>
          <div className="relative rounded-xl w-full">
            {popularRewards.length > 0 ? (
              <SliderWrapper
                items={popularRewards}
                renderItem={(reward, index) => (
                  <PopularRewardCard
                    key={index}
                    imageUrl={reward.imageUrl}
                    category={reward.CategoryName}
                    title={reward.name}
                    coinAmount={reward.fundcoinCost}
                    buyersCount={reward.buyersCount || 0}
                    buyersImages={reward.profilePictures || []}
                    onClick={() => handleRewardClick(reward.rewardId)}
                  />
                )}
              />
            ) : (
              <p className="text-center text-[#787B7E] mt-10">No popular rewards available</p>
            )}
          </div>
        </div>

        <div className="mt-12 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2 mb-10">My Donations</h2>
          <div className="w-full h-40 bg-gray-100 rounded-lg"></div>
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
        <BottomTabsBar />
      </div>

      {showWelcomeBack && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-transparent">
          <WelcomeBackScreen onClose={() => setShowWelcomeBack(false)} />
        </div>
      )}
    </>
  );
};

export default Dashboard;