import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/common/button';
import DonationOptionCard from 'src/components/chooseDonation/donationOptionCard';
import AppDonationThree from './appDonation-three';
import { useAuth } from 'src/contexts/authContext'; // Import the AuthContext

interface AppDonationProps {
  onClose: () => void;
}

const AppDonation: React.FC<AppDonationProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const proportionalAmount: number = parseFloat(process.env.REACT_APP_FUNDR_COIN_PROPORTIONAL_AMOUNT || '0');
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null); // Track selected donation amount
  const { setAppDonationAmount } = useAuth(); // Use the context to set the app donation amount
  const [showAppDonationThree, setShowAppDonationThree] = useState(false);

  const handleDonationSelect = (amount: number) => {
    setSelectedAmount(amount); // Store the selected amount
  };

  const handleSupportFundr = () => {
    if (selectedAmount !== null) {
      setAppDonationAmount(selectedAmount); // Store the app donation amount in context
      navigate('/cause-payment-receipt'); // Navigate to the payment receipt screen
      onClose(); // Close the pop-up
    }
  };

  const handleNoDonation = () => {
    setShowAppDonationThree(true);
  };

  const donationOptions = [
    { amount: 1, coins: 1 * proportionalAmount },
    { amount: 2, coins: 2 * proportionalAmount },
    { amount: 3, coins: 3 * proportionalAmount }
  ];

  if (showAppDonationThree) {
    return <AppDonationThree onClose={onClose} />;
  }

  return (
    <div className="w-auto">
      <div className="fixed inset-0 z-10 bg-transparent max-w-[548px] mx-auto" style={{ backdropFilter: 'blur(2px)' }} />
      <div className="fixed inset-x-0 bottom-0 bg-[#0A1E32] bg-opacity-90 rounded-t-3xl z-40 h-[calc(90%-2rem)] max-w-[548px] w-full mx-auto p-6">
        <div className="p-4 w-full">
          <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-white mb-4">
            Want an
            <span className="text-4xl font-normal text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
              {' '}
              Extra 50 fundcoin{' '}
            </span>
            to spend each month?
          </h2>
          <p className="text-md mb-4 text-white">
            We rely on your donations to keep this app FREE for our Nonprofits. We appreciate your donation.
          </p>

          <div className="flex-grow space-y-2 overflow-y-hidden overflow-x-hidden scrollbar-hide pb-8">
            {donationOptions.map((option, index) => (
              <DonationOptionCard
                key={index}
                amount={option.amount}
                coins={option.coins}
                checked={selectedAmount === option.amount} // Ensure only one option is selected
                onCheckChange={() => handleDonationSelect(option.amount)} // Store the amount when a donation is selected
                backgroundColor="bg-[#0A1E32] bg-opacity-20"
              />
            ))}
          </div>
          <p className="text-md mb-4 text-[#3D86FF]">
            Note:
            <span className="text-sm mb-4 text-[#8197AD]">
              {' '}
              Donate $1 to support our app and we’ll give you an extra 45 fundcoin per month.
            </span>
          </p>
          <Button onClick={handleSupportFundr} disabled={selectedAmount === null}>
            Yes! I want to Support Fundr
          </Button>
          <div className="mt-4 space-y-4 text-center mt-6">
            <button className="text-[#8197AD] mt-2" onClick={handleNoDonation}>
              No, I don't want to help
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDonation;
