import React, { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import default styles
import images from 'src/assets/images'; // Ensure this path is correct

interface InputFieldProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
}

const InputField: React.FC<InputFieldProps> = ({ type, placeholder, value, onChange }) => {
  const [inputType, setInputType] = useState(type);

  const togglePasswordVisibility = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === 'string') {
      onChange(e); // For PhoneInput, pass the value directly
    } else {
      onChange(e); // For regular input fields, pass the event object
    }
  };

  if (type === 'phone') {
    return (
       <div className="relative w-full mb-6">
        <PhoneInput
          country={'us'}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    );
  }

  return (
    <div className="relative w-full mb-6">
      <input
        type={inputType}
        placeholder={placeholder}
        className="w-full border-2 rounded-input-custom py-2 px-4 text-gray-500 bg-[rgba(33,45,54,0.06)] border-transparent outline-none focus:border-[#3D86FF]"
        value={value}
        onChange={handleChange}
      />
      {type === 'password' && (
        <img
          src={inputType === 'password' ? images.hide : images.show}
          alt="Toggle password visibility"
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-7 h-7"
          onClick={togglePasswordVisibility}
        />
      )}
    </div>
  );
};


interface OTPInputProps {
  length: number;
  value: string;
  onChange: (value: string) => void;
  onComplete: () => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, value, onChange, onComplete }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (value.length === length) {
      onComplete();
    }
  }, [value, length, onComplete]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = e.target.value;
    const newOtp = value.split('');
    newOtp[index] = newValue;
    onChange(newOtp.join(''));

    if (newValue && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      const newOtp = value.split('');
      newOtp[index] = '';
      onChange(newOtp.join(''));

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, length);
    onChange(pasteData);

    const filledLength = pasteData.length;
    if (filledLength < length) {
      inputRefs.current[filledLength]?.focus();
    } else {
      inputRefs.current[length - 1]?.focus();
    }
  };

  return (
    <div className="flex space-x-2 justify-center">
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          type="tel" // Changed from "text" to "tel" to open numeric keypad
          maxLength={1}
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          ref={(el) => (inputRefs.current[index] = el)}
          className="w-10 h-12 sm:w-12 sm:h-14 md:w-14 md:h-16 border-2 rounded-lg text-center text-gray-800 bg-[rgba(33,45,54,0.06)] border-transparent outline-none focus:border-[#3D86FF] transition-all"
        />
      ))}
    </div>
  );
};

export { InputField, OTPInput };