import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useRedirectIfNoState = (key: string) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as Record<string, any>;

  useEffect(() => {
    if (!state || !state[key]) {
      navigate(`/${key}`);
    }
  }, [state, key, navigate]);
};

export default useRedirectIfNoState;
