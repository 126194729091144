import React, { useState, useEffect, useRef } from 'react';
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import SliderWrapper from 'src/components/slider/sliderWrapper';
import PopularRewardCard from 'src/components/reward/popularRewardCard';
import HorizontalScroll from 'src/components/common/horizontalScroll';
import images from 'src/assets/images';
import { fetchPopularRewards } from 'src/services/dashboard/api';
import { fetchRewards } from 'src/services/reward/api';
import RewardCard from 'src/components/reward/rewardCard';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/contexts/authContext';
type Reward = {
  id: string;
  name: string;
  fundcoinCost: number;
  imageUrl: string;
  rewardId: number;
  CategoryName: string;
  buyersCount: number;
  profilePictures: string[];
};

type PopularReward = Reward;

const Rewards: React.FC = () => {
  const navigate = useNavigate() 
  const [selectedCategory, setSelectedCategory] = useState<string>("All Rewards");
  const [popularRewards, setPopularRewards] = useState<PopularReward[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [fundCoinBalance, setFundCoinBalance] = useState(0);
  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { user } = useAuth();

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    if (category === 'All Rewards') {
      if (contentRef.current) {
        contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      const categoryElement = categoryRefs.current[category];
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const colors = ['#00BCD5', '#FF8490', '#FFA674'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const popularRewardsData: any = await fetchPopularRewards();
        setPopularRewards(popularRewardsData);
        setFundCoinBalance(user.fundcoinBalance || 0);
        const rewardsData: any = await fetchRewards();
        setRewards(rewardsData);
        const uniqueCategories: any = [
          ...new Set(rewardsData.map((reward: Reward) => reward.CategoryName)),
        ];
        setCategories(['All Rewards', ...uniqueCategories]);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  const handleRewardClick = (rewardId: string) => {
  navigate(`/reward-detail/${rewardId}`);
  };
  return (
    <>
      <div className="p-4 mt-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Rewards</h1>
          <div className="flex items-center bg-[#0A1E32] text-xl text-white py-1 px-3 rounded-full">
            <img src={images.fund_coin_icon} alt="Coin Icon" className="w-5 h-5 mr-2" />
            {fundCoinBalance}
          </div>
        </div>
        <div className="p-4 -mt-2 -ml-4">
          <HorizontalScroll>
            <div className="text-sm flex space-x-3 -ml-2">
              {categories.map((category, index) => (
                <button 
                  key={index} 
                  onClick={() => handleCategoryClick(category)}
                  className={`py-1 px-4 rounded-full whitespace-nowrap ${
                    selectedCategory === category
                      ? 'bg-[#3D86FF] text-white'
                      : 'bg-[#E5E5E5] text-[#808283]'
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </HorizontalScroll>
        </div>
      </div>

      <div ref={contentRef} className="flex-grow px-6 -mt-10 -ml-3 mr-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
        <div className="mt-4 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">Popular Rewards</h2>
          <div style={{ position: 'relative', borderRadius: '12px', width: '105%', marginLeft: '4px' }}>
            {popularRewards.length > 0 ? (
              <SliderWrapper
                items={popularRewards}
                renderItem={(reward, index) => (
                  <PopularRewardCard
                    key={index}
                    imageUrl={reward.imageUrl}
                    category={reward.CategoryName}
                    title={reward.name}
                    coinAmount={reward.fundcoinCost}
                    buyersCount={reward.buyersCount || 0}
                    buyersImages={reward.profilePictures || []}
                    showBuyNow={true}
                    backgroundColor={colors[index % colors.length]}
                    onClick={() => handleRewardClick(reward.id)}
                  />
                )}
              />
            ) : (
              <p className="text-center text-[#787B7E] mt-10">No popular rewards available</p>
            )}
          </div>
        </div>

        {categories.slice(1).map((category) => (
          <div key={category} ref={(el) => (categoryRefs.current[category] = el)} className="mt-12 w-full">
            <h2 className="text-lg font-bold text-gray-900 ml-2">{category}</h2>
            <div className="grid gap-4 mt-4">
              {rewards
                .filter((reward) => reward.CategoryName === category)
                .map((reward, index) => (
                  <RewardCard
                    key={index}
                    imageUrl={reward.imageUrl}
                    category={reward.CategoryName}
                    title={reward.name}
                    coinAmount={reward.fundcoinCost}
                    buyersCount={reward.buyersCount || 0}
                    buyersImages={reward.profilePictures || []}
                    backgroundColor={colors[index % colors.length]}
                    onClick={() => handleRewardClick(reward.id)}
                  />
                ))}
            </div>
          </div>
        ))}

        <div className="mt-12 w-full">
          <div className="w-full h-40 bg-gray-100 rounded-lg"></div> 
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
        <BottomTabsBar />
      </div>
    </>
  );
};

export default Rewards;
