import React from 'react';

interface AboutUsSectionProps {
  content: string;
}

const AboutUsSection: React.FC<AboutUsSectionProps> = ({ content }) => {
  const paragraphs = content.split('\n').filter(paragraph => paragraph.trim() !== '');

  return (
    <div className="bg-[#F4F4F4] rounded-2xl shadow-md p-4 mb-6 w-full">
      <h3 className="text-lg font-bold text-gray-900 mb-2">About Us</h3>
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="text-sm text-gray-700 mt-2">
          {paragraph}
        </p>
      ))}
    </div>
  );
};

export default AboutUsSection;
