import React, { useState } from 'react';
import Slider from 'react-slick';
import { sliderStyles } from 'src/components/slider/styles/sliderStyles';

interface SliderWrapperProps {
  items: any[];
  renderItem: (item: any, index: number) => React.ReactNode;
}

const SliderWrapper: React.FC<SliderWrapperProps> = ({ items, renderItem }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const isSingleSlide = items.length === 1;

  const sliderSettings = {
    dots: !isSingleSlide,
    infinite: !isSingleSlide,
    autoplay: !isSingleSlide,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: any, next: any) => setActiveSlide(next),
    appendDots: (dots: React.ReactNode) => (
      <div style={sliderStyles.dotsContainer}>
        <ul style={sliderStyles.dotsList}>{dots}</ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div style={sliderStyles.dot(i === activeSlide)} />
    ),
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Slider {...sliderSettings}>
        {items.map((item, index) => (
          <div key={index}>
            {renderItem(item, index)}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderWrapper;
