import React from 'react';
import LineProgressBar from 'src/components/cause/lineProgressBar';
interface CauseCardProps {
  causeLogo: string;
  causeName: string;
  buyersCount: number;
  currentAmount: string;
  goalAmount: string;
  onClick: () => void;
}

const CauseCard: React.FC<CauseCardProps> = ({
  causeLogo,
  causeName,
  buyersCount,
  currentAmount,
  goalAmount,
  onClick,
}) => {
  const percentage = goalAmount
    ? (parseFloat(currentAmount.replace(/[^0-9.-]+/g, "")) / parseFloat(goalAmount.replace(/[^0-9.-]+/g, ""))) * 100
    : 0;

  return (
    <div
      onClick={onClick}
      className="rounded-xl shadow-lg overflow-hidden bg-[#F0F2F5] bg-opacity-30 flex items-center p-4 cursor-pointer"
      style={{ width: '100%' }}
    >
      <div className="flex-shrink-0">
        <img
          src={causeLogo}
          alt={`${causeName} logo`}
          className="w-12 h-12 rounded-full"
        />
      </div>
      <div className="flex flex-col ml-4 flex-grow">
        <h3 className="text-lg font-bold text-gray-900">{causeName}</h3>
        <p className="text-sm text-[#0A1E32] opacity-60">{buyersCount} funders</p>
        
        <div className="mt-2">
          <LineProgressBar percentage={percentage} height='2px' />
          <p className='mt-3 text-sm text-black'>
            <strong className="text-black font-bold">{`$${currentAmount}.00`}</strong>{' '}
            <span className="text-[#8298AE]">raised out of </span>
            <strong className="text-[#8298AE]">{`$${goalAmount}`}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CauseCard;
