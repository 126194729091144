import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import images from 'src/assets/images'; 

const SplashScreen: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/introduction-one');
    }, 5000); 

    return () => clearTimeout(timer); 
  }, [navigate]);

  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center p-4 bg-darkblue">
      <div className="relative w-[375px] h-[812px] rounded-3xl shadow-lg overflow-hidden flex flex-col items-center justify-center p-4 bg-darkblue">
        <div className="absolute inset-0 bg-cover bg-center rounded-3xl" style={{ backgroundImage: `url(${images.splashBackground})` }}></div>
        <div className="relative z-10 flex flex-col items-center">
          <img src={images.logo} alt="Fundr Logo" className="w-40 h-40 mb-2" />
          <p className="text-l mt-2" style={{ color: '#8197AD' }}>Rewarding Good People</p>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
