import React from 'react';
import images from 'src/assets/images';

interface PopularRewardCardProps {
  imageUrl: string;
  category: string;
  title: string;
  coinAmount: number;
  buyersCount: string;
  buyersImages: string[];
  showBuyNow?: boolean;
  backgroundColor?: string;
  onClick: () => void;
}

const PopularRewardCard: React.FC<PopularRewardCardProps> = ({
  imageUrl,
  category,
  title,
  coinAmount,
  buyersCount,
  buyersImages,
  showBuyNow = false,
  backgroundColor = '#00BCD5',
  onClick,
}) => {
  const textColor = backgroundColor === '#00BCD5' ? 'text-white' : 'text-black';

  return (
    <div
      onClick={onClick}
      className="rounded-xl shadow-lg overflow-hidden h-74 w-82 bg-white">
      <div className="relative">
        <img src={imageUrl} alt={title} className="w-full h-40 object-cover" />
        <div className="absolute top-2 right-3 p-2 h-7 w-17 rounded-full bg-[#0A1E32] flex items-center bg-opacity-80">
          <img
            src={images.fund_coin_icon}
            alt="Coin Icon"
            className="w-4 h-4"
          />
          <span className="text-white ml-2">{coinAmount}</span>
        </div>
      </div>
      <div className={`p-4 ${textColor} rounded-b-xl`} style={{ backgroundColor }}>
        <h3 className="text-xs uppercase">{category}</h3>
        <h2
          className="text-xl font-bold"
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </h2>
        <div className="mt-4 flex items-center relative ml-5">
          {buyersImages.slice(0).reverse().map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Buyer ${index + 1}`}
              className="w-10 h-10 rounded-full border-2 border-blue"
              style={{
                marginLeft: `${-3 * 6}px`,
                zIndex: index + 1,
              }}
            />
          ))}
          <div
            className="w-10 h-10 bg-white text-black text-xs rounded-full flex items-center justify-center border-2 border-white"
            style={{
              zIndex: buyersImages.length + 1,
              marginLeft: `${-buyersImages.length * 6}px`,
            }}
          >
            {buyersCount}
          </div>
          <span className="ml-4 text-xs">
            Others who <br />bought this
          </span>
          {showBuyNow && (
            <div className="absolute -right-1">
              <button className="relative text-sm font-bold py-2 px-6 rounded-xl overflow-hidden">
                <span
                  className="absolute inset-0 bg-white"
                  aria-hidden="true"
                ></span>
                <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400">
                  BUY NOW
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopularRewardCard;
