import React from 'react';
import images from 'src/assets/images';

interface UserCauseCardProps {
  causeWallPaper: string;
  causeLogo: string;
  causeName: string;
  raisedAmount: number;
  goalAmount: number;
  onClick: () => void; 
}

const UserCauseCard: React.FC<UserCauseCardProps> = ({
  causeWallPaper,
  causeLogo,
  causeName,
  raisedAmount,
  goalAmount,
  onClick, 
}) => {
  const progress = (raisedAmount / goalAmount) * 100;
  const height = 6; 
  const radius = height / 2; 

  return (
    <div 
      onClick={onClick} // Make the card clickable
      className="rounded-xl shadow-lg overflow-hidden bg-[#FBDFDF] w-60 h-72 flex-shrink-0 cursor-pointer"
    >
      <div className="relative">
        <img src={causeWallPaper} alt={causeName} className="w-full h-32 object-cover" />
        <div className="absolute top-1 left-3 p-1 rounded-full bg-transparent">
          <div className="p-0.5 rounded-full" 
               style={{
                 background: 'linear-gradient(to right, #EC4899, #FB923C)',
               }}>
            <div className="p-[2px] bg-[#FBDFDF] rounded-full">
              <img
                src={causeLogo} 
                alt="Cause Logo"
                className="w-8 h-8 rounded-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="absolute top-3 right-3">
          <button className="p-2 rounded-full">
            <img 
              src={images.reload_icon} 
              alt="Reload Icon"
              className="w-4 h-4"
              style={{ backgroundColor: 'transparent' }} 
            />
          </button>
        </div>
      </div>
      <div className="p-4">
        <h3 className="font-bold text-lg text-gray-900 truncate">{causeName}</h3>
        <p>
          <strong className="text-black">${raisedAmount.toLocaleString()}</strong>{' '}
          <span className="text-[#787B7E]">raised</span>
        </p>
        <div className="w-full mt-2">
          <svg width="100%" height={height}>
            <rect
              x="0"
              y="2"
              width="100%"
              height={height - 4}
              rx={radius}
              ry={radius}
              fill="#787B7E" 
            />
            <rect
              x="0"
              y="0"
              width={`${progress}%`}
              height={height}
              rx={radius}
              ry={radius}
              fill="#0A1E32" 
            />
          </svg>
        </div>
        <p className="text-[#787B7E] mt-2 text-sm">
          July Goal: ${goalAmount.toLocaleString()}
        </p>
      </div>
    </div>
  );
};

export default UserCauseCard;
