import React from 'react';
import images from 'src/assets/images'; // Ensure the correct path for your images

interface FundcoinRewardCardProps {
  fundcoinAmount: number;
  donationAmount: number;
}

const FundcoinRewardCard: React.FC<FundcoinRewardCardProps> = ({ fundcoinAmount, donationAmount }) => {
  return (
    <div className="relative w-full bg-[#0A1E32] rounded-2xl p-6 mb-8 flex flex-col items-center">
      <div className="absolute -top-10 bg-white p-2 rounded-full flex items-center justify-center">
        <img src={images.plus_icon} alt="Plus Icon" className="w-16 h-16" /> {/* Replace with your actual image */}
      </div>
      <div className="flex items-center mt-6">
        <img src={images.fund_coin_icon} alt="Coin Icon" className="w-12 h-12 mr-2" /> {/* Add your coin icon here */}
        <h3 className="text-6xl font-bold text-white">
          {fundcoinAmount}
        </h3>
      </div>
      <p className="text-lg text-white mt-2 text-center">
        You have earned 
        <span
          className="bg-clip-text text-bold text-transparent"
          style={{
            background: 'linear-gradient(to right, #EC4899, #FB923C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {' '}{fundcoinAmount} fundcoin{' '}
        </span>
        for your donation of ${donationAmount}.
      </p>
    </div>
  );
};

export default FundcoinRewardCard;
