import React from 'react';

interface UserRewardCardProps {
  imageUrl: string;
  category: string;
  title: string;
  onClick: () => void;
}

const UserRewardCard: React.FC<UserRewardCardProps> = ({ imageUrl, category, title, onClick}) => {
  return (
    <div
      onClick={onClick}
      className="rounded-xl shadow-lg overflow-hidden h-62 w-82 bg-white">
      <div className="relative">
        <img 
          src={imageUrl} 
          alt={title} 
          className="w-full h-30 object-cover" 
          style={{ maxHeight: '120px', maxWidth: '100%' }} 
        />
      </div>
      <div className="p-4">
        <h3
          className="text-xs"
          style={{
            background: 'linear-gradient(to bottom, #EC4899, #FB923C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {category}
        </h3>
        <h1 className="text-lg font-bold text-gray-900">{title}</h1>
        <button
          className="mt-4 w-full text-white py-2 rounded-xl"
          style={{
            background: 'linear-gradient(to right, #EC4899, #FB923C)',
          }}
        >
          VIEW REWARD DETAILS
        </button>
      </div>
    </div>
  );
};

export default UserRewardCard;
