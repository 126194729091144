import React from 'react';

interface DonationProgressBarProps {
  currentAmount: number;
  yearlyGoal: number;
}

const DonationProgressBar: React.FC<DonationProgressBarProps> = ({ currentAmount, yearlyGoal }) => {
  const percentage = (currentAmount / yearlyGoal) * 100;
  const radius = 140; // Fixed radius for the circle
  const backgroundStrokeWidth = 8;
  const progressStrokeWidth = 18;
  const circumference = 2 * Math.PI * radius;
  const visibleCircumference = circumference * 0.65;
  const filled = (percentage / 100) * visibleCircumference;
  const offset = circumference - filled;

  return (
    <div className="relative w-full flex items-center justify-center mx-auto" style={{ maxWidth: '500px' }}>
      <svg className="w-full h-auto transform rotate-[-208deg]" viewBox="0 0 300 300">
        <defs>
          <linearGradient id="progress-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#FB923C', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <circle
          cx="150"
          cy="150"
          r={radius}
          stroke="#E5EAEF"
          strokeWidth={backgroundStrokeWidth}
          fill="transparent"
          strokeLinecap="round"
          strokeDasharray={`${visibleCircumference} ${circumference * 0.35}`}
          strokeDashoffset="0"
        />
        <circle
          cx="150"
          cy="150"
          r={radius}
          stroke="url(#progress-gradient)"
          strokeWidth={progressStrokeWidth}
          fill="transparent"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          style={{
            transition: 'stroke-dashoffset 0.5s ease',
          }}
        />
      </svg>
      <div className="absolute inset-0 flex flex-col items-center justify-center px-4">
        <div className="text-sm sm:text-lg font-bold text-gray-800 text-center">TOTAL DONATIONS</div>
        <div className="text-3xl sm:text-5xl font-bold text-gray-800 relative pb-2 sm:pb-4">
          <span className="absolute text-base sm:text-2xl" style={{ left: '-10px' }}>$</span>
          <span className="ml-1">{currentAmount.toLocaleString()}</span>
          <div className="mt-2 border-b-2 sm:border-b-4 w-full" style={{ borderColor: '#E3E8ED' }}></div>
        </div>
        <div className="text-sm sm:text-lg font-bold text-center" style={{ color: '#8298AE' }}>Yearly Goal</div>
        <div className="text-xl sm:text-3xl font-bold text-gray-800 relative pb-2 sm:pb-4" style={{ color: '#8298AE' }}>
          <span className="absolute text-base sm:text-xl" style={{ left: '-10px' }}>$</span>
          <span className="ml-1">{yearlyGoal.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default DonationProgressBar;
