import React from 'react';
import images from 'src/assets/images'; // Make sure to replace this with the correct path to your assets

interface FundcoinPurchaseCardProps {
  coinAmount: number;
  onBuyNow: () => void;
}

const FundcoinPurchaseCard: React.FC<FundcoinPurchaseCardProps> = ({ coinAmount, onBuyNow }) => {
  return (
    <div className="flex justify-between items-center bg-[#0A1E32] p-8 rounded-t-2xl w-full shadow-md">
      <div className="flex items-center space-x-2">
        <img src={images.fund_coin_icon} alt="Fundcoin Icon" className="w-8 h-8" />
        <div>
          <span className="text-white text-2xl font-bold">{coinAmount}</span>
          <p className="text-xs text-white">FUNDCOIN</p>
        </div>
      </div>
      <button
        onClick={onBuyNow}
        className="flex items-center justify-center bg-gradient-to-r from-pink-500 to-orange-400 text-white text-md py-4 px-10 rounded-xl shadow-md"
      >
        BUY NOW
      </button>
    </div>
  );
};

export default FundcoinPurchaseCard;
