import { post, get } from "../request";
import { API_URLS } from "src/constants/apiUrls";

export const fetchUserCauses = async (param: any) => {
  try {
    const data = await post(API_URLS.fetchUserCauses, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCauses = async () => {
  try {
    const data = await get(API_URLS.fetchCauses);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchPopularCauses = async () => {
  try {
    const data = await get(API_URLS.fetchPopularCauses);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCause = async (param: any) => {
  try {
    const data = await post(API_URLS.fetchCause, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const cancelCauseSubscription = async (param: any) => {
  try {
    const data = await post(API_URLS.cancelCauseSubscription, param);
    return data;
  } catch (error) {
    throw error;
  }
};