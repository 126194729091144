import { app, RecaptchaVerifier, signInWithPhoneNumber } from 'src/firebaseConfig';
import { getAuth } from 'firebase/auth';

declare var grecaptcha: {
  reset: (widgetId?: string) => void;
};

const auth = getAuth(app);

export const sendOtp = async (phoneNumber: string): Promise<void> => {
  const recaptchaContainer = document.getElementById('recaptcha-container');
  if (!recaptchaContainer) {
    console.error('reCAPTCHA container not found');
    return;
  }

  // Clean and validate the phone number
  let cleanedNumber = phoneNumber.replace(/[^0-9+]/g, '');
  if (!cleanedNumber.startsWith('+')) {
    cleanedNumber = `+${cleanedNumber}`;
  }

  if (!/^\+\d{1,15}$/.test(cleanedNumber)) {
    console.error('Invalid phone number format');
    return;
  }

  try {
    // Always reinitialize reCAPTCHA before sending OTP
    if (window.recaptchaVerifier) {
      console.log('Clearing existing reCAPTCHA...');
      window.recaptchaVerifier.clear();
      delete window.recaptchaVerifier;
    }

    console.log('Initializing new reCAPTCHA...');
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response: any) => {
        console.log('reCAPTCHA solved:', response);
      },
      'expired-callback': () => {
        console.log('reCAPTCHA expired, resetting...');
        window.recaptchaVerifier.render().then((widgetId: any) => {
          grecaptcha.reset(widgetId);
        });
      }
    });

    const appVerifier = window.recaptchaVerifier;
    const confirmationResult = await signInWithPhoneNumber(auth, cleanedNumber, appVerifier);
    window.confirmationResult = confirmationResult; 
    console.log('OTP sent');
  } catch (error) {
    console.error('Error sending OTP:', error);
  }
};

export const verifyOtp = async (otp: string): Promise<string | null> => {
  try {
    const confirmationResult = (window as any).confirmationResult;
    const result = await confirmationResult.confirm(otp);
    const idToken = await result.user.getIdToken();
    return idToken;
  } catch (error) {
    console.error('Error verifying OTP:', error);
    return null;
  }
};
