import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import Button from 'src/components/common/button';
import { InputField } from 'src/components/common/inputField';
import images from 'src/assets/images'; 
import { CheckUser } from 'src/services/auth/api';
import { toast } from 'react-toastify';

const EmailInputScreen: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleNext = async () => {
    setLoading(true);
    try {
      const data = { email };
      const userExists = await CheckUser(data);
      localStorage.setItem('email', email);
      
      if (userExists) {
        navigate('/password', { state: { email } });
      } else {
        navigate('/name', { state: { email } });
      }
    } catch (error: any) {
      console.error('Error checking user existence:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/introduction-four'); 
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === 'string') {
      setEmail(e); 
    } else {
      setEmail(e.target.value); 
    }
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center p-4 bg-white">
      <div className="absolute top-6 flex justify-between items-center w-full px-4">
        <img src={images.leftarrow} alt="Back" className="w-4 h-4 cursor-pointer mt-3" onClick={handleBack} />
        <div className="flex-grow flex justify-center">
          <ProgressBar step={0} color="#0A1E32" />
        </div>
      </div>

      <div className="mt-16 w-full max-w-md text-left">
        <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
          Let’s get started!
        </h1>
        <p className="text-lg mb-4 text-gray-800">
          To join or sign in, please enter your email below.
        </p>
        <InputField
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleChange}
        />
      </div>

      <div className="w-full max-w-md mt-auto mb-10 px-2">
        <Button onClick={handleNext} disabled={!email || loading}>
          {loading ? 'Loading...' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default EmailInputScreen;
