import React from 'react';

interface ProgressBarProps {
  step: number;
  color?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ step, color }) => {
  const dots = [
    { condition: step === 2 },
    { condition: step >= 1 },
    { condition: step >= 0 && step < 2 },
    { condition: step < 1 },
  ];

  const barStyle = color ? { backgroundColor: color } : { backgroundImage: 'linear-gradient(to right, #ff2eab, #ffa030)' };

  return (
    <div className="flex items-center justify-center space-x-2 mt-4">
      {dots.slice(0, 2).map((dot, index) => (
        dot.condition && <div key={index} className="w-2 h-2 rounded-full bg-[#8298AE]"></div>
      ))}
      <div className="w-8 h-2 rounded-full" style={barStyle}></div>
      {dots.slice(2).map((dot, index) => (
        dot.condition && <div key={index + 2} className="w-2 h-2 rounded-full bg-[#8298AE]"></div>
      ))}
    </div>
  );
};

export default ProgressBar;
