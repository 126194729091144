import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { API_URLS } from 'src/constants/apiUrls';
const axiosInstance = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosNoTokenInstance = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getNewToken = async (): Promise<string> => {
  try {
    const user = localStorage.getItem('user');
    if (!user) {
      throw new Error('No user object found in local storage');
    }

    const parsedUser = JSON.parse(user);
    const refreshToken = parsedUser.refreshToken;
    const response = await axiosInstance.post<{ accessToken: any, refreshToken: any }>(API_URLS.newToken, {
      token: refreshToken
    });

    const newAccessToken = response.data.accessToken;
    const newRefreshToken = response.data.refreshToken;
      parsedUser.accessToken = newAccessToken;
      parsedUser.refreshToken = newRefreshToken;
      localStorage.setItem('user', JSON.stringify(parsedUser));
    return newAccessToken;
  } catch (error) {
    console.log("error", error);
    throw new Error('Failed to refresh token');
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      try {
        const newToken = await getNewToken();
        localStorage.setItem('token', newToken);
        const config: any = error.config;
        if (config.headers) {
          config.headers.Authorization = `Bearer ${newToken}`;
        }
        return axiosInstance.request(config);
      } catch (tokenError) {
        return Promise.reject(tokenError);
      }
    }
    return Promise.reject(error);
  }
);

interface ApiResponse<T> {
  data: T;
}

interface ApiErrorResponse {
  error: string;
}


export const post = async <T>(
  path: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosInstance.post<ApiResponse<T>>(path, data, config);
    return response.data.data as T;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (axiosError.response?.data?.error) {
        throw new Error(axiosError.response.data.error);
      } else {
        throw new Error(axiosError.message);
      }
    } else {
      throw new Error(error.message);
    }
  }
};

export const get = async <T>(
  path: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosInstance.get<ApiResponse<T>>(path, config);
    return response.data.data as T;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (axiosError.response?.data?.error) {
        throw new Error(axiosError.response.data.error);
      } else {
        throw new Error(axiosError.message);
      }
    } else {
      throw new Error(error.message);
    }
  }
};

export const postWithoutToken = async <T>(
  path: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosNoTokenInstance.post<ApiResponse<T>>(path, data, config);
    return response.data.data as T;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (axiosError.response?.data?.error) {
        throw new Error(axiosError.response.data.error);
      } else {
        throw new Error(axiosError.message);
      }
    } else {
      throw new Error(error.message);
    }
  }
};

export const getWithoutToken = async <T>(
  path: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosNoTokenInstance.get<ApiResponse<T>>(path, config);
    return response.data.data as T;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (axiosError.response?.data?.error) {
        throw new Error(axiosError.response.data.error);
      } else {
        throw new Error(axiosError.message);
      }
    } else {
      throw new Error(error.message);
    }
  }
};