import React from 'react';
import images from 'src/assets/images'; 
import ProgressBar from 'src/components/common/progressBar';
import { useNavigate } from 'react-router-dom';
const IntroScreenFour: React.FC = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/email');
  };
  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center p-4 bg-darkblue">
        <div className="absolute inset-0 bg-cover bg-center rounded-3xl" style={{ backgroundImage: `url(${images.intro_four})` }}></div>
        <div className="absolute bottom-8 z-10 flex flex-col items-center text-center px-6"> 
          <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Get rewarded for<br /><span className="block">your donations</span>
          </h1>
          <p className="text-s mb-10" style={{ color: '#8197AD' }}>
             We give you Fundcoin (our in app currency) which can be used to buy<br /><span className="block">our amazing offers and prizes.</span>
          </p>
          <ProgressBar step={2} />
          <button className="relative py-0.5 px-0.5 rounded-custom text-white bg-transparent border-none mt-8 w-full max-w-md" onClick={handleNext}> 
            <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-orange-400 rounded-custom p-px"></span>
            <span className="relative block py-3 px-12 font-bold text-lg">Get Started</span>
          </button>
        </div>
      </div>
  );
};

export default IntroScreenFour;
