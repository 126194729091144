import React from 'react';

const Loader: React.FC = () => {
  return (
    <div className="flex space-x-2 relative">
      <div className="w-2 h-2 rounded-full dot dot-1"></div>
      <div className="w-2 h-2 rounded-full dot dot-2"></div>
      <div className="w-2 h-2 rounded-full dot dot-3"></div>
      <div className="w-2 h-2 rounded-full dot dot-4"></div>
    </div>
  );
};

export default Loader;
