import React from 'react';

interface RouteCardProps {
  title: string;
  buttonText: string;
  onClick: () => void;
  cardBackgroundColor?: string; 
  buttonBackgroundColor?: string; 
  image: string; 
  buttonIcon?: string;
}

const RouteCard: React.FC<RouteCardProps> = ({
  title,
  buttonText,
  onClick,
  cardBackgroundColor = 'bg-blue-500', 
  buttonBackgroundColor = 'bg-blue-700',
  image, 
  buttonIcon, 
}) => {
  return (
    <div className={`${cardBackgroundColor} rounded-3xl p-4 flex flex-col justify-between h-full w-full`}>
      <div className="flex justify-between items-center mb-4">
        <div className="text-white text-lg max-w-full">
          {title}
        </div>
        <div
          className="w-8 h-8 bg-white"
          style={{
            maskImage: `url(${image})`,
            maskSize: 'contain',
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            WebkitMaskImage: `url(${image})`,
            WebkitMaskSize: 'contain',
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskPosition: 'center',
          }}
        />
      </div>
      <button
        onClick={onClick}
        className={`${buttonBackgroundColor} text-white py-2 px-4 rounded-2xl mt-2 flex items-center justify-center`}
      >
        <span>{buttonText}</span>
        {buttonIcon && (
          <img src={buttonIcon} alt="Icon" className="w-5 h-5 ml-2" />
        )}
      </button>
    </div>
  );
};

export default RouteCard;
